<script>
import { apiRecordList } from "../utils/api.js";
import HeaderHome from './HeaderHome.vue';
import FooterHome from './FooterHome.vue';
import CommBanner2 from "./CommBanner2.vue";

export default {
  components:{
     HeaderHome,FooterHome,CommBanner2
  },
  data() {
    return {
      lists: [],
    };
  },
  mounted() {
    apiRecordList()
      .then((res) => {
        this.lists = res.data;
        /*
        this.recordTitle = res.data.title;
        this.recordDuration=res.data.record_duration;
        if(res.data.transfer_status==0){
            this.transferStatus="未转写";
        }else if(res.data.transfer_status==2){
            this.transferStatus="已完成";
        }else{
            this.transferStatus="未开始";
        }
        */
        //获取record信息；
        console.log(res);
      })
      .catch((res) => {
        alert("网络开小差了，请稍后再试试！");
        console.log(res);
      });
  },
  methods: {
      getRecordContent(record_id){
        this.$router.push({ 
            path: "/record_content",
            query:{record_id:record_id}
           }); //成功后跳转
      },
      goOnTransfer(record_id){
        this.$router.push({ 
            path: "/audio_info",
            query:{record_id:record_id}
           }); //成功后跳转
      },
  },
};
</script>
<style scoped>


</style>
<template >
    <HeaderHome />
  <!--main-->

  <CommBanner2/>

  <div style="margin:30px auto;width:1000px;">
    <el-steps :active="5" align-center>
      <el-step title="步骤1" description="上传音频"></el-step>
      <el-step title="步骤2" description="生成订单"></el-step>
      <el-step title="步骤3" description="支付时长卡"></el-step>
      <el-step title="步骤4" description="等待转写完成"></el-step>
      <el-step title="步骤5" description="查看/导出"></el-step>
    </el-steps>
  </div>

  <div style="width: 1000px; background: #fff; padding: 20px; margin: 0 auto;">
    <p class="subtitle">所有转写订单</p>

    <div class="my-div">
      <el-table
      :data="lists"
      header-align="center"	
      height="600"
      :header-cell-style="{background:'#eef1f6',color:'#606266'}"
      style="width: 1000px;">
      <el-table-column type="index" width="150" label="序号" align="center"></el-table-column>
      <el-table-column
        align="center"
        prop="title"
        label="名称"
        width="200">
      </el-table-column>
      <el-table-column
        align="center"
        prop="audio_duration"
        label="音频时长"
        width="200">
      </el-table-column>
      <el-table-column
        align="center"
        prop="create_time"
        label="创建时间"
        width="200">
      </el-table-column>
      <el-table-column label="转写状态" width="150" align="center">
        <template #default="scope">
          <el-tag type="info" effect="plain" v-if="scope.row.transfer_status==1 || scope.row.transfer_status==0">未开始</el-tag>
          <el-tag type="primary"  effect="plain" v-if="scope.row.transfer_status==2">转写中</el-tag>
          <el-tag type="success" effect="plain" v-if="scope.row.transfer_status==3">已转写</el-tag>
          <el-tag type="danger" effect="plain" v-if="scope.row.transfer_status==4">转写错误</el-tag>
          <el-tag type="danger" effect="plain" v-if="scope.row.transfer_status==5">转写状态异常</el-tag>
        </template>
      </el-table-column>

      <el-table-column
        align="center"  
        label="操作"
        width="97">
        <template #default="scope">
          <el-tag type="info" :style="{ 'cursor': 'pointer' }" effect="dark" @click="goOnTransfer(scope.row.record_id)" v-if="scope.row.transfer_status==1 || scope.row.transfer_status==0">继续</el-tag>
          <el-tag type="primary" :style="{ 'cursor': 'pointer' }" effect="dark" @click="getRecordContent(scope.row.record_id)" v-if="scope.row.transfer_status==2">查看</el-tag>
          <el-tag type="success" :style="{ 'cursor': 'pointer' }" effect="dark" @click="getRecordContent(scope.row.record_id)" v-if="scope.row.transfer_status==3">查看</el-tag>
          <el-tag type="danger" :style="{ 'cursor': 'pointer' }" effect="dark" @click="getRecordContent(scope.row.record_id)" v-if="scope.row.transfer_status==4">查看</el-tag>

        </template>
      </el-table-column>
      </el-table>        
    </div>

  </div>
   

  <!--底部关于我们begin-->
  <FooterHome />
  <!--底部关于我们end-->

</template>
<style>
    *{
      padding:0;
      margin:0;
      box-sizing:border-box;
    }

    .my-div {
      margin: 0 auto;
      border: 1px solid #ddd; /* 设置边框的大小、类型和颜色 */
      width: 1000px;
    } 
</style>
<script>
import { apiGetRecord, apiTransferPay,apiGetAssets } from "../utils/api.js";
import qs from "qs";
import HeaderHome from './HeaderHome.vue';
import FooterHome from './FooterHome.vue';
import CommBanner2 from './CommBanner2.vue';


export default {
  components:{
     HeaderHome,FooterHome,CommBanner2
  },
  data() {
    return {
      tableData:[],
      //recordTitle: "",
      //recordDuration:'',
      transferOrder:"",
      assetsLists: [],
      assetsFlag:"",
      assetsIDs:[],
    };
  },
  mounted() {
    let record_id = this.$route.query.record_id;
    let post_data = qs.stringify({ record_id: record_id });
    apiGetRecord(post_data)
      .then((res) => {
        let apiData = {"recordTitle":res.data.title,"recordDuration":res.data.audio_duration,"transfer_order":res.data.transfer_order};
        this.tableData.push(apiData);

      //   this.recordTitle = res.data.title;
      // this.recordDuration=res.data.audio_duration;
        this.transferOrder=res.data.transfer_order;
        //获取record信息；
        console.log(res);
      })
      .catch((res) => {
        alert("网络开小差了，请稍后再试试！");
        console.log(res);
      });
    
    apiGetAssets()
      .then((assetsRes) => {
        if(assetsRes.code==200){
          if(assetsRes.data == undefined || assetsRes.data.length <= 0 ){
            this.assetsFlag="empty";
          }else{
            this.assetsLists = assetsRes.data;
          }
        }
      })
      .catch((assetsRes) => {
        alert("网络开小差了，请稍后再试试！");
        console.log(assetsRes);

      });
  
  },
  methods: {
    apiStartTransferClick(){
      let record_id = this.$route.query.record_id;
      let post_data = qs.stringify({ record_id: record_id,order_id:this.transferOrder,asset_ids: this.assetsIDs.toString()});

      apiTransferPay(post_data)
      .then((res) => {
        if(res.code==50012){
          alert("您的转写时长卡不足，请充值后再进行转写！");
          return false;
        }
        if(res.code!=200){
          alert("网络异常提交数据有误，请稍后再试试！");
          return false;
        }else{
           this.$router.push({ 
            path: "/record",
            query:{record_id:record_id}
           }); //成功后跳转
        }
      })
      .catch((res) => {
        alert("网络开小差了，请稍后再试试！");
        console.log(res);
      });
    }
  },
};
</script>
<template >
  <HeaderHome />

  <CommBanner2 />

  <div style="margin:30px auto;width:1000px;">
    <el-steps :active="3" align-center>
      <el-step title="步骤1" description="上传音频"></el-step>
      <el-step title="步骤2" description="生成订单"></el-step>
      <el-step title="步骤3" description="支付时长卡"></el-step>
      <el-step title="步骤4" description="等待转写完成"></el-step>
      <el-step title="步骤5" description="查看/导出"></el-step>
    </el-steps>
  </div>


  <div style="width: 1000px; background: #fff; padding: 20px; margin: 0 auto;">
    <p class="subtitle">1、再次确认订单</p>
    <div class="my-div">
      <el-table
      :data="tableData"
      height="100"
      :header-cell-style="{background:'#eef1f6',color:'#606266'}"
      style="width: 1000px;">
      <el-table-column type="index" width="150" label="序号" align="center"></el-table-column>
      <el-table-column
        prop="recordTitle"
        label="订单名称"
        width="250">
      </el-table-column>
      <el-table-column
        prop="recordDuration"
        label="音频时长"
        width="200">
      </el-table-column>
      <el-table-column
        prop="transfer_order"
        label="转写订单"
        width="200">
      </el-table-column>
      <el-table-column
        prop="recordDuration"
        label="扣除时长"
        width="197">
      </el-table-column>
      </el-table>   
    </div>
    <div class="clear"></div>
    <p class="subtitle" style="padding-top: 15px">2、请选择时长卡支付</p>
  
    <!--用户权益begin--->
    <div class="my-div2" v-if="this.assetsFlag!='empty'">
    <div v-if="this.assetsFlag!='empty'">
        <table id="table-7"> <!-- Replace "table-1" with any of the design numbers -->
        <thead>
            <th>选择</th>
            <th>时长卡</th>
            <th>时长余额</th>
            <th>购买时间</th>
            <th>到期时间</th>
        </thead>
        <tbody v-for='(assetsItem,index) in assetsLists' :key="index">

            <tr>
                <td> <input type="checkbox" v-model="assetsIDs" v-bind:value="assetsItem.asset_id" v-bind:id="assetsItem.asset_id"></td>
                <td>{{ assetsItem.goods_name }}</td>
                <td>{{ assetsItem.value }}</td>
                <td>{{ assetsItem.create_time }}</td>
                <td>{{ assetsItem.expire_to }}</td>
            </tr>
        </tbody>
        </table>
      </div>
    </div>
    <!--用户权益end-->
    <div v-if="this.assetsFlag=='empty'" style="margin:30px auto;">
      <span>
        当前时长卡余额不足，请先<a href="/recharge" target="_blank" style="color:red;font-weight:bold;">【去充值】!</a>
      </span>
      <div class="clear"></div>
    </div>
    <div v-if="this.assetsFlag!='empty'" style="width: 1000px;display: block;margin:30px;text-align: center;">
        <el-button type="primary" @click="apiStartTransferClick">开始转写</el-button>
    </div>
  </div>
  
  

  <!--底部关于我们begin-->
  <FooterHome />
  <!--底部关于我们end-->

</template>

<style scoped>
.my-div2 {
  margin: 0 auto;
  border: 1px solid #ddd; /* 设置边框的大小、类型和颜色 */
  width: 1000px;
} 
/* Table Head */
#table-7 {
  width: 100%;
}
#table-7 thead th {
    background-color: #3366FF;
    color: #fff;
    border-bottom-width: 0;
}
/* Column Style */
#table-7 td {
    color: #000;
}
/* Heading and Column Style */
#table-7 tr, #table-7 th {
    border-width: 1px;
    border-style: solid;
    border-color: rgb(81, 130, 187);
}
/* Padding and font style */
#table-7 td, #table-7 th {
    padding: 5px 10px;
    font-size: 12px;
    font-family: Verdana;
    font-weight: bold;
    text-align: center;
}
</style>
<script>
import HeaderHome from '../HeaderHome.vue';
import FooterHome from '../FooterHome.vue';
import CommBanner2 from '../CommBanner2.vue';

export default {
  name: "UserLogin",
  components:{
     HeaderHome,FooterHome,CommBanner2
  },
  data() {
    return {
        regForm: {
            tel: "",
            username: "",
            password: "",
            password_check: "",
            email: "",
      },
    };
  },
  methods: {
  },
};
</script>
<template>
    <HeaderHome />
    <CommBanner2 />
    <div class="container">
        <h1>关于我们</h1>
        <h2>极速录音转写</h2>
        <p>我们是一个小而精的团队，专注于录音转文字在语音领域的应用与落地。</p>

        <h1>联系我们</h1>
        <h2>客服微信</h2>
        <p>微信号：xxxxx</p>
    </div>
    <FooterHome />
</template>
<style scoped>
.container h1{
    font-size: 30px;
    font-weight: 700;
    color: #424853;
    margin-bottom: 30px;
    margin-top: 20px;
    text-align: center;
}
.container h2{
    font-size: 21px;
    font-weight: 500;
    color: #000000;
    margin: 20px 0;
    text-align: center;
}
.container p{
    font-size: 14px;
    color: #000000;
    margin: 20px 0;
    text-align: center;
}
</style>
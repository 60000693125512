<template>
    <div class="privacy-content">
            <p class="title">隐私政策</p>
            <p>生效日期：2021年【7】月【28】日</p>
            <p><strong>引言</strong></p>
            <p>极速语音转写是由北京xx信息技术有限公司为用户（以下简称“您”）提供的在线转文字产品（以下简称“计算语音转写应用”或“本应用”）。我们通过计算语音转写应用为您提供录音转文字、拍照转文字等功能，我们现在或将来还可能为您提供其他产品/服务（以下简称“计算语音转写应用产品与服务”）。我们根据《中华人民共和国网络安全法》、《常见类型移动互联网应用程序必要个人信息范围规定》等相关法律法规制定本政策，并保护您的个人信息安全。</p>
            <p>本政策介绍了我们为您提供计算语音转写应用产品与服务的过程中收集、使用、存储、共享、转让、公开披露您个人信息的目的、方式、范围和信息安全保护措施，以及您管理个人信息的权利和实现方法。请您结合<strong><a href="./protocol.html" id="protocolLink">《计算语音转写用户服务协议》</a></strong>仔细阅读本政策，并在同意本政策约定内容的情况下使用我们的产品或服务。</p>
            <p><strong>以下是我们的特别提示，请您重点关注：</strong></p>
            <p>1.对重点内容，我们采用<strong>“加粗”</strong>的方式进行提示。</p>
            <p><strong>2.对个人敏感信息，我们用<em>“加粗斜体”</em>的书写方式进行特别提醒。在您提供任何个人敏感信息前，您已慎重考虑并在此确认该等提供是适当的，您在此同意您的个人敏感信息可按照本政策所述的目的和方式进行处理。</strong></p>
            <p>3.本政策已将计算语音转写应用程序必要个人信息收集范围单独列明，请您重点关注。我们不会收集与提供服务无关的个人信息，也<strong>不会因您不同意收集非必要个人信息，而拒绝您使用本应用的基本功能服务</strong>。若您不同意基本功能服务以外所需的个人信息被收集使用的，请您不要开启本应用的各项业务功能。</p>
            <p><strong>目录</strong></p>
            <p><strong>1.</strong> <strong>如何收集和使用您的个人信息</strong></p>
            <p><strong>2.</strong> <strong>如何使用Cookie</strong></p>
            <p><strong>3.</strong> <strong>如何委托处理、共享、转让及披露您的个人信息</strong></p>
            <p><strong>4.</strong> <strong>您如何管理您个人信息</strong></p>
            <p><strong>5.</strong> <strong>如何存储您的个人信息</strong></p>
            <p><strong>6.</strong> <strong>如何保护您的个人信息</strong></p>
            <p><strong>7.</strong> <strong>如何保护未成年人个人信息</strong></p>
            <p><strong>8.</strong> <strong>如何更新本政策</strong></p>
            <p><strong>9.</strong> <strong>如何联系我们</strong></p>
            <p><strong>10.附则</strong></p>
            <p><strong>11.</strong> <strong>关键词定义</strong></p>
            <p><strong>1.如何收集和使用您的个人信息</strong></p>
            <p>我们会根据正当、合法、透明、最小必要的原则，基于本政策所述的目的，收集和使用您的个人信息。</p>
            <p><strong>1.1产品功能服务</strong></p>
            <p>为了向您提供优质的录音、拍照转文字产品和服务，我们可能需要收集下述信息。如果您拒绝提供，将无法享用对应的业务功能和服务。</p>
            <p><strong>向您提供产品与服务过程中您主动提供的个人信息：</strong></p>
            <p><strong>1.1.1 账户信息</strong></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;①当您通过手机短信验证或一键登录的方式登录本应用，您需要向我们提供您的手机号码、短信验证码以完成登录；②如您不登录计算语音转写应用账号，我们不会收集您的上述个人信息，但与登录相关的功能将无法使用。</p>
            <p><strong>1.1.2&nbsp;<em>语音信息</em></strong></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当您使用录音转文字功能时，您需要向我们提供拟转写的<strong><em>音频信息</em></strong>，本应用需要将音频上传到云端进行转文字处理并为您返回转写后的文字内容，这是实现前述功能所必需的。同时请您知晓，如您已经同意授权麦克风权限，我们仅会在您主动使用客户端内麦克风或音频录制功能时，才会调取麦克风获取语音信息。您可以随时在手机系统设置中关闭相关权限。</p>
            <p><strong>1.1.3&nbsp;<em>图像信息</em></strong></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当您使用拍照转文字功能时，您需要向我们提供您待扫描的<strong><em>图像信息</em></strong>，以便我们通过图片转文字OCR功能识别图像上的<strong><em>文字信息</em></strong>，向您提供转化后的结果。您可以随时在手机系统设置中关闭相关权限。</p>
            <p><strong>1.1.4 联系人信息</strong></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;①为了及时解决您的意见反馈、投诉或咨询本产品的相关问题，您需要向我们提供您的真实联系方式，以及问题相关的证明材料（包括<strong><em>图片、视频</em></strong>或<strong><em>文本信息</em></strong>）。此外，请您知悉，系统可能会记录您与客服之间的沟通记录、处理方案及结果。如您不提供上述信息，我们可能无法核验身份、定位问题并向您及时反馈。</p>
            <p><strong>1.2 向您提供产品与/或服务过程中我们主动收集的个人信息</strong></p>
            <p><strong>1.2.1 设备信息</strong></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为了使计算语音转写应用与设备进行必要的适配并安全服务，在您使用本应用时，根据您授予的具体权限，我们会收集您的设备型号、<strong><em>设备识别码（安卓ID、OAID、IMEI、Mac地址）、</em></strong>系统版本。收集这些信息是为了帮助我们进行bug分析，保障您正常使用本应用的服务、改进和优化我们的产品体验、保障您的账号安全。我们不会将该等信息与您的个人身份信息进行匹配，除本政策另有明确约定外，我们也不会将您的设备信息提供给任何第三方。<strong>上述设备信息是为提供服务所收集的基础信息，如您不想设备信息被我们收集，则可能导致您无法正常使用相关功能服务。</strong>您可以随时在手机系统设置中关闭相关权限。</p>
            <p><strong>1.2.2&nbsp;<em>日志信息</em></strong></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当您使用计算语音转写产品与服务时，我们会收集您的相关<strong><em>网络日志信息，包括网络崩溃、系统活动信息、浏览记录、点击记录、软件使用记录、IP地址信息，</em></strong>以便于我们改善产品与服务，给您更好的服务体验。</p>
            <p><strong>1.2.3&nbsp;<em>交易记录</em></strong></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;①当您在「时长卡充值」中购买付费转文字所需的时长卡时，我们会收集您的<strong><em>交易记录</em></strong>，以便完成购买流程。②<strong>上述功能可能收集您的个人敏感信息，如您不想提供此类信息，请不要使用该功能。</strong></p>
            <p><strong>1.3 计算语音转写应用所调用的系统权限</strong></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如上所述，基于对本应用的业务功能的使用，您可以选择是否授权开启相关功能对应的必要权限：</p>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <p><strong>权限类型</strong></p>
                        </td>
                        <td>
                            <p><strong>对应功能</strong></p>
                        </td>
                        <td>
                            <p><strong>功能收集的信息</strong></p>
                        </td>
                        <td>
                            <p><strong>可否关闭</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>摄像头</p>
                        </td>
                        <td>
                            <p>拍照转文字</p>
                        </td>
                        <td>
                            <p>照片或图片信息</p>
                        </td>
                        <td>
                            <p>是</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>麦克风</p>
                        </td>
                        <td>
                            <p>录音转文字</p>
                        </td>
                        <td>
                            <p>语音输入的语音信息</p>
                        </td>
                        <td>
                            <p>是</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>存储</p>
                        </td>
                        <td>
                            <p>导入音频转文字</p>
                        </td>
                        <td>
                            <p>读取本地的音频</p>
                        </td>
                        <td>
                            <p>是</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>相册</p>
                        </td>
                        <td>
                            <p>拍照转文字</p>
                        </td>
                        <td>
                            <p>读取相册中的图片</p>
                        </td>
                        <td>
                            <p>是</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>设备状态</p>
                        </td>
                        <td>
                            <p>账户及登录安全，优化用户体验、安全排查、识别是否为常用设备、设备绑定情况。</p>
                        </td>
                        <td>
                            <p>设备型号、设备识别码、</p>
                        </td>
                        <td>
                            <p>是</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p><strong>1.4 我们从第三方获取的您的个人信息</strong></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们可能会在您的同意范围内从第三方（我们的合作方）处收集并使用您的个人信息。我们保证依照相关法律法规的规定处理您的个人信息，同时请您详细阅读该第三方的隐私政策及用户服务协议。如您拒绝第三方在提供服务时收集、使用您的个人信息，将可能导致您无法使用本应用的部分业务功能。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本软件涉及第三方服务包括第三方SDK登录和支付服务：有关第三方登录和支付服务可能收集的信息详见附录<a href="/privacy_third">“第三方合作伙伴收集个人信息情况列表”</a>。</p>
            <p><strong>1.5 征得同意的例外</strong></p>
            <p>根据法律法规，以下情形中收集和使用您的个人信息无需征得您的同意：</p>
            <p>(1) 与履行法律法规规定的义务相关的；</p>
            <p>(2) 与国家安全、国防安全直接有关的；</p>
            <p>(3) 与公共安全、公共卫生、重大公共利益有关的；</p>
            <p>(4) 与刑事侦查、起诉、审判和判决执行等有关的；</p>
            <p>(5) 出于维护您或其他用户的生命、财产等重大合法权益但又很难得到本人同意的；</p>
            <p>(6) 所涉及的个人信息是您自行向社会公众公开的；</p>
            <p>(7) 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
            <p>(8) 根据您的要求签订和履行合同所必需的；</p>
            <p>(9) 维护所提供的计算语音转写产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
            <p>(10) 发生公司合并、收购或破产清算，运营主体变更时的转让的；</p>
            <p>(11) 法律法规及监管政策规定的其他情形。</p>
            <p><strong>2. 如何使用Cookie</strong></p>
            <p>为确保计算语音转写应用正常运转，为您访问更便捷、获得更感兴趣的内容，我们可能会将通过追踪技术（Cookie）收集所得的信息与我们收集所得关于您的其他信息合并。Cookie是您登录网站或浏览网络内容时，网站服务器存放在您终端设备内的小型文件，通常包含标识符、站点名称以及一些号码和字符。当您再次访问相应网站时，网站就可通过
                Cookie 识别您的浏览。Cookie可能会存储用户偏好及其他信息。</p>
            <p>我们一般会在以下场景使用Cookie技术：</p>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <p><strong>目的分类</strong></p>
                        </td>
                        <td>
                            <p><strong>场景示例</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>识别用户</p>
                        </td>
                        <td>
                            <p>当您使用账号登录本应用时，借助于Cookie，我们可以识别您是否属于我们的用户。</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>偏好设置</p>
                        </td>
                        <td>
                            <p>借助于Cookie，我们能够保存您之前的设置，例如拍照转文字的识别模式设置等内容。</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>优化用户体验</p>
                        </td>
                        <td>
                            <p>如果您在使用本应用时遇到一些错误或漏洞，我们会通过Cookie进行记录，以便及时修复。</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p>您可以通过在系统清除缓存的方式删除通过追踪技术已存储在本地的缓存数据，也可以通过手机本身的设置撤回对该App使用存储权限的授权来拒绝本地数据缓存，但可能导致相关用户设置丢失，并且，我们可能无法向您提供更加优质的使用体验。</p>
            <p><strong>3. 如何委托处理、共享、转让及披露您的个人信息</strong></p>
            <p><strong>3.1 委托处理</strong></p>
            <p>我们会委托提供技术服务的第三方SDK提供商处理您的个人信息。关于提供商的身份、收集个人信息的目的、方式、范围等情况。如果您拒绝我们的供应商在提供服务时收集为提供服务所必需的个人信息，将可能导致您在使用计算语音转写产品与服务的过程中无法享受该供应商提供的服务。</p>
            <p><strong>3.2 共享</strong></p>
            <p><strong>除非存在以下一种或多种情形，否则我们不会与任何第三方公司、组织和个人共享您的个人信息：</strong></p>
            <p>(1) 基于您自行提出的要求、或事先获得您的明示授权或同意；</p>
            <p>(2) 与合作方共享：为了提供更完善、优质的产品和服务，我们的某些服务将由合作方提供。我们可能会与合作方共享您的某些个人信息，以提供更好的客户服务和用户体验。目前，我们的合作方包括以下类型：</p>
            <p> a) 第三方SDK合作伙伴。关于提供商的身份、收集个人信息的目的、方式、范围等情况，请您详细参见本政策附录<a href="/privacy_third">“第三方合作伙伴收集个人信息情况列表”</a>。</p>
            <p> (3) 本政策第1.5条约定的情形。</p>
            <p><strong>3.3 转让</strong></p>
            <p><strong>原则上，我们不会将您的个人信息控制权向第三方转让，但本政策第1.5条约定的情形除外。</strong></p>
            <p><strong>请您知悉：</strong>如具备上述事由确需转让，我们将在转让前向您告知转让信息的目的、类型、信息受让方，并在征得您同意后再转让，但法律法规另有规定的除外。</p>
            <p><strong>3.4 公开披露</strong></p>
            <p><strong>原则上我们不会向社会或不特定人群发布您的个人信息，但以下情况除外：</strong></p>
            <p>(1) 基于与您的约定的；</p>
            <p>(2) 根据法律法规应当披露的。</p>
            <p><strong>4. 您如何管理您的个人信息</strong></p>
            <p><strong>4.1 访问或更正您的个人信息</strong></p>
            <p>您有权随时登录并访问您的计算语音转写账号，查看您的个人信息，包括账号信息和个人资料。如您发现我们收集、存储、使用、披露的您的个人信息有错误的，或者在法律法规规定的其他情况下，您可以更正您的个人信息。</p>
            <p>对于您的个人资料，您可以点击 “我的”-“头像”-“个人资料”进行更正或修改。</p>
            <p><strong>4.2 删除您的个人信息</strong></p>
            <p>在以下情形中，您可以向我们提出删除您个人信息的请求，但已进行个人信息匿名化处理或法律法规另有规定的除外：</p>
            <p>(1) 我们违反法律、行政法规的规定或与您的约定，收集、存储、使用、转移、披露您的个人信息的；</p>
            <p>(2) 我们超出目的范围或者必要期限收集、存储、使用、转移、披露您的个人信息的；</p>
            <p>(3) 您通过注销等方式终止使用计算语音转写产品与/或服务的；</p>
            <p>(4) 法律法规等规定的其他情形。</p>
            <p><strong>您在此同意，一旦删除请求被响应，您的个人信息将被删除或匿名化处理，</strong>除非法律法规或规范性文件另有规定。</p>
            <p><strong>请您知悉，我们可能不会将响应的信息立即从备份系统中删除，但会在备份系统更新时进行删除。</strong></p>
            <p><strong>4.3 注销您的账号</strong></p>
            <p><strong>您可通过以下路径“设置-问题反馈”，向我们提出注销您的已注册账号的请求，我们会在核实您的身份信息后为您注销账号。当您注销账号后，我们将删除或匿名化处理您的个人信息。</strong></p>
            <p><strong>4.4 响应您的上述请求</strong></p>
            <p>为实现本政策第4.1-4.4条所述的用户权利，您可通过本政策第9条提供的方式与我们联系。为确保安全，我们会验证您的身份，然后再处理您的请求。对于您的请求，我们原则上将于收到您的请求后的15个工作日内做出答复。但对于与您的身份不直接关联的信息或无端重复信息，或者需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。</p>
            <p><strong>5. 如何存储您的个人信息</strong></p>
            <p>我们会将在中国境内运营所收集和产生的个人信息存储在中国境内。在本政策下所收集及处理的个人信息不存在出境的情况。</p>
            <p>我们承诺，除非法律法规另有要求，我们对您的个人信息的存储时间将限于实现您授权使用的目的所必需的最短时间。</p>
            <p>如我们因业务需求或其他原因出现停止运营的情况，除非法律法规或规范性文件另有规定，我们将会向您告知此类情况，同时删除已收集到的个人信息或对其做匿名化处理。</p>
            <p><strong>6. 如何保护您的个人信息</strong></p>
            <p>我们十分重视您的个人信息安全，为此我们采用了符合行业标准的安全技术措施及配套的组织架构和管理体系等多层面保护措施，以最大程度防止您的个人信息被泄露、毁损、误用、未授权访问、未授权披露或更改，具体包括：</p>
            <p><strong>6.1 数据安全技术措施：</strong></p>
            <p>在数据安全传输方面，我们采用了传输层安全协议等密码技术，通过Https等方式防止传输链路被窃听、截取，保证数据的私密性和完整性；我们还会使用Token技术保护数据在其传输过程中的安全，并使用妥善的保护机制以防止数据遭到恶意攻击；此外，我们还将对您的信息采取加密存储等额外的安全保护措施。</p>
            <p>在数据访问和使用的安全控制方面，我们实施了严格的数据权限控制机制，采取多重身份认证技术，并对处理您的个人信息的行为进行监控，避免数据被违规访问和未授权使用。我们还对数据生命周期的全流程进行监控，定期对本产品的所有接口进行安全性扫描，并采取防范恶意代码、流量攻击等保护措施，防止您的个人信息遭遇未授权的访问、公开披露、使用、修改、人为或意外的损坏或丢失等。</p>
            <p><strong>6.2 数据安全组织和管理措施</strong></p>
            <p>根据有关法规要求，我们成立了专门负责个人信息保护的部门，并指定专人负责儿童个人信息保护。我们还建立了相关的内控管理流程，以最小授权为原则，对可能接触到您个人信息的工作人员，严格设定信息访问权限，控制个人信息的知悉范围。</p>
            <p>我们建立了数据安全使用的内部规范制度，保障对您个人信息的处理，在收集、传输、使用、存储、转移、销毁等环节均满足法律法规的安全要求。根据有关法规要求，对需要访问个人信息的工作人员，我们会要求其经过个人信息保护负责人或者其授权的管理人员审批，记录访问情况，并采取技术措施，避免违法复制、下载个人信息。</p>
            <p>我们组织员工参加安全与隐私保护相关培训并要求其完成规定的考核，加强员工对于保护个人信息重要性的认知。</p>
            <p><strong>6.3 安全事件处理</strong></p>
            <p>为应对个人信息泄露、损毁和丢失等可能出现的风险，我们建立了专门的应急响应团队，针对不同安全事件启动安全预案，进行止损、分析、制定补救措施、联合相关部门进行溯源和打击。同时我们将按照法律法规的要求，及时将事件相关情况告知您，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
            <p>请您知悉并理解，互联网并非绝对安全的环境，我们强烈建议您通过安全方式、使用复杂密码，协助我们保证您的账号安全。如您发现自己的个人信息泄密，尤其是您的账号或密码发生泄露，请联系&nbsp;aitext@qingqingxinyu-inc.com，以便我们采取相应措施来保护您的信息安全。</p>
            <p><strong>7. 如何保护未成年人个人信息</strong></p>
            <p>我们根据我国法律法规的要求，保护未成年人的个人信息安全。如果您是：<br>（1）儿童（详情请查阅关键词定义）使用者，必须由您的监护人阅读并同意本协议的全部内容以及《xx公司儿童隐私保护指引》后，我们方才向您提供计算语音转写产品与服务；<br>                （2）已满14周岁的未成年人使用者，必须由您的监护人陪同阅读本协议的全部内容，并在监护人或您本人同意本协议的全部内容后，我们才向您提供计算语音转写产品与服务。如我们有证据证明您违反了上述约定，我们有权停止提供我们的产品与服务。
            </p>
            <p>对于经监护人同意而收集未成年人个人信息的情况，我们只会在法律允许、监护人明确同意或者保护未成年人所必要的情况下使用或公开披露该信息。如我们发现在未事先获得监护人同意的情况下收集了被其监护的未成年人的个人信息，我们会尽快删除相关信息。</p>
            <p><strong>8. 如何更新本政策</strong></p>
            <p><strong>我们保留不时更新或修改本政策的权利。但是未经您明确同意，我们不会削减您按照本政策所应享有的权利。该等变更构成本政策的一部分。我们会在本产品中发布更新后的本政策内容。</strong></p>
            <p>请您仔细阅读变更后的本政策内容。如果您不同意本政策，或对本政策修改、更新的内容有异议，您可以选择停止使用计算语音转写产品与服务或者注销计算语音转写账号。但请您知悉，您账号注销之前、停止使用本服务之前的行为和活动仍受本政策的约束。</p>
            <p><strong>若涉及重大、实质性变更，我们会依据具体情况，以显著的方式通知您。</strong></p>
            <p>重大、实质性变更的情形包括但不限于以下情形：</p>
            <p>（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
            <p>（2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</p>
            <p>（3）个人信息共享、转让或公开披露的主要对象发生变化；</p>
            <p>（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
            <p>（5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；</p>
            <p>（6）个人信息安全影响评估报告表明存在高风险时。</p>
            <p><strong>9. 如何联系我们</strong></p>
            <p>如您在使用本应用时对本隐私政策有任何问题，您可通过发送邮件至aitext@qingqingxinyu-inc.com或通过本应用中的“设置-意见反馈及投诉”渠道或搜狗网站上公示的其他联系方式与我们联系，您也可以将您的问题邮寄至北京市海淀区中关村东路1号院搜狐网络大厦9层
                。
            </p>
            <p>我们将尽快审核所涉问题，并在收到您请求后的<strong>15个工作日内</strong>给予回复。若您对我们的回复不满意且我们拒绝改进的，您可以向相关监管机构进行投诉和举报。</p>
            <p><strong>10. 附则</strong></p>
            <p>本政策的解释及争议解决均应适用中华人民共和国法律。与本政策相关的任何纠纷，双方应经友好协商解决；若不能协商解决，您在此同意将争议提交至北京市海淀区人民法院。</p>
            <p>如果有管辖权的任何法院裁定或判决本政策的任何条款无效，则该条款将从本政策中移除，但该条款的无效不影响本政策其余条款的效力。本政策的其余条款将继续执行。</p>
            <p>本政策的标题仅为方便阅读而设计，不影响本政策任何条款的含义或解释。</p>
            <p><strong>11. 关键词定义</strong></p>
            <p>计算语音转写应用：指由北京xx信息计算有效公司或其关联公司开发的计算语音转写App及其各自版本。</p>
            <p>关联公司：指北京xx信息计算有效公司合并报表范围内的下属公司或其他关联企业，且该公司/企业现在或将来与北京xx信息计算有效公司具有控制、受控制或处于其他企业的共同控制下的关系。</p>
            <p>计算语音转写产品与/或服务：指计算语音转写向您提供的以下产品与/或服务：<br>（1）通过计算语音转写应用提供的转文字产品与/或服务；<br>（2）与上述产品与/或服务相关的任何软件；<br>（3）我们不时为您提供的其他产品与/或服务。</p>
            <p>用户或您：指使用计算语音转写产品与服务的用户。</p>
            <p>未成年人：指年龄不超过18周岁的未成年人。</p>
            <p>儿童：指未满14周岁的未成年人。</p>
            <p>个人信息：以电子或者其他方式记录的、能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人信息包括个人基本信息、个人身份信息、个人生物识别信息、网络身份标识信息、个人健康生理信息、个人教育工作信息、个人财产信息、个人通信信息、联系人信息、个人上网记录、个人常用设备信息、个人位置信息等。为免疑义，个人信息包括但不限于个人敏感信息。</p>
            <p>个人敏感信息：指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。个人敏感信息包括个人财产信息、个人健康生理信息、个人生物识别信息、个人身份信息等。</p>
            <p>匿名化：指通过对个人信息的技术处理，使得您无法被识别，且处理后的信息不能被复原的过程。</p>
            <p>中国或中国境内：指中华人民共和国大陆地区（仅为本政策之目的，不包含香港特别行政区、澳门特别行政区和台湾地区）。</p>
            <p>设备：指可用于访问计算语音转写产品与/或服务的装置</p>
        </div>
</template>
<script>
import HeaderHome from "./HeaderHome.vue";
import FooterHome from "./FooterHome.vue";
import CommBanner2 from './CommBanner2.vue';


import qs from "qs";

import {apiGetOrderInfo, apiCreateOrderProduct,apiGetPayStatus,apiCloseTimecardOrder } from "../utils/api.js";

import layer from "layui-layer";

export default {
  components: {
    HeaderHome,
    FooterHome,
    CommBanner2,
  },
  data() {
    return {
      orderID:'',
      productID:'',
      productName:'',
      totalMoney:'',
      qrcode:'',


      num:0,
      minutes: '', //倒计时分钟
      seconds: '', //倒计时秒
      isEnd: false, //支付超时
    };
  },
  created:function(){
    if (!localStorage.getItem("token")) {
        this.$router.push({
          path: "/login",
          query: { record_id: "dd" },
        });
        return false;
    }
    let orderID = this.$route.query.order_id
    let post_data = qs.stringify({ order_id: orderID });
    apiGetOrderInfo(post_data)
      .then((res) => {
        this.orderID = res.data.order_id;
        this.productID = res.data.product_id;
        this.totalMoney = res.data.total_money;
        this.productName = res.data.product_name;
        this.qrcode =res.data.qrcode;
        /*
        this.recordTitle = res.data.title;
        this.recordDuration=res.data.record_duration;
        if(res.data.transfer_status==0){
            this.transferStatus="未转写";
        }else if(res.data.transfer_status==2){
            this.transferStatus="已完成";
        }else{
            this.transferStatus="未开始";
        }
        */
        //获取record信息；
        console.log(res);
      })
      .catch((res) => {
        alert("网络开小差了，请稍后再试试！");
        console.log(res);
      });

      // 每隔3s实现轮询
      this.timer=window.setInterval(() => {
        setTimeout(this.getPayState(orderID), 0);
      }, 3000);

      this.countdown();

  },
  mounted() {
  },
  methods: {
    b(productID, productName, price) {
      //未登录跳转首页
      if (!localStorage.getItem("token")) {
        this.$router.push({
          path: "/login",
          query: { record_id: "dd" },
        });
        return false;
      }
      let content = "<p>您即将购买以下时长卡</p>";
      content += "<p>商品：" + productName + "</p>";
      content += "<p>价格：" + price + "元";
      layer.open({
        title: "请确认购买信息",
        btn: ["确认支付"], //修改按钮文字
        type: 0, //0（信息框，默认）1（页面层）2（iframe层）3（加载层）4（tips层）
        //  area: ["300px", "160px"], //宽高
        shade: false, //不需要遮罩层，设置模态与非模态的关键
        content, //内容
        yes: function (index) {
          let post_data = qs.stringify({ product_id: productID });

          apiCreateOrderProduct(post_data)
            .then((res) => {
              //this.lists = res.data;
              alert(res.data)
              layer.close(index); //关闭弹窗

              //获取record信息；
              console.log(res);
            })
            .catch((res) => {
              alert("网络开小差了，请稍后再试试！");
              console.log(res);
            });

        },
        /*
        cancel: function (index, layero) {
          //关闭弹窗时的回调函数
          if (confirm("确定要关闭么")) {
            //只有当点击confirm框的确定时，该层才会关闭
            layer.close(index);
            console.log(layero);
          }
          return false;
        },
        */
      });
    },
    c() {
      alert("ddd");
    },
    // 订单倒计时
    countdown () {
            // 当前时间
            let nowTime = new Date()
            let orderIDstr = this.$route.query.order_id
            let orderIDstr2 = orderIDstr.substring(0,14)
            // 获取创建订单15分钟后的时间,this.payForm.create_time:订单创建时间
            var newDate = orderIDstr2.replace(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/, "$1-$2-$3 $4:$5:$6");
            let endTime = new Date(newDate);
            endTime.setMinutes(endTime.getMinutes() + 5) // 结束时间，获取创建订单15分钟后的时间

            // 两个日期相差的时间戳，以毫秒为单位（1000ms = 1s）
            let totalTime = endTime - nowTime

            // 结束时间大于现在的时间
            if(totalTime>0){
                let timer = setInterval(() => {

                    if (totalTime >= 0) {

                        //获取分钟数
                        let minutes = 
                            Math.floor((((totalTime % (3600 * 24 * 1000)) / 1000) % 3600) / 60);
                        //获取秒数
                        let seconds = 
                            Math.floor((((totalTime % (3600 * 24 * 1000)) / 1000) % 3600) % 60).toString().padStart(2, "0");

                        this.minutes = minutes
                        this.seconds = seconds

                        totalTime -= 1000;
                        // console.log(totalTime)
                    } else {
                        clearInterval(timer); // 停止调用函数 
                        this.isEnd = true;
                        let orderID = this.$route.query.order_id;
                        this.closeTimecardOrder(orderID);
                    }
                }, 1000); 

            }else{
                this.isEnd = true
            }  
    },

    // getPayState 获取支付状态
    getPayState (orderID) {
      let post_data = qs.stringify({ order_id: orderID });
      apiGetPayStatus(post_data)
      .then((res) => {
        if (res.data.status==2){
          alert("购买时长卡成功!");
          //结束轮询清除定时器
          window.clearInterval(this.timer)
          this.$router.push("/member");
          console.log(res);
        }
      })
      .catch((res) => {
        alert("网络开小差了，请稍后再试！");
        console.log(res);
      });


      this.num ++ ;
      console.log('监听支付状态第 ' + this.num + '次');
    },
    // closeTimecardOrder 关闭时长卡购买订单
    closeTimecardOrder(orderID){
      let post_data = qs.stringify({ order_id: orderID });
      apiCloseTimecardOrder(post_data)
      .then((res) => {
        if (res.code==0){
          alert("订单已过期关闭,请重新下单购买!");
          this.$router.push("/recharge");
        } else {
          alert("订单已过期,关闭失败,请重新下单购买!");
          this.$router.push("/recharge");
        }
      })
      .catch(() => {
        alert("网络开小差了，请稍后再试!");
      });
    },



  },

  
};
</script>
<style scoped>
</style>
<template >
  <HeaderHome />
  
  <CommBanner2/>

  <div class="clear"></div>
  <div class="ailine">尊敬的用户，您正在进行时长卡购买，请确认购买信息： </div>
  <div class="aicommon">
    <div class="aileft">
        <div>订单编号： {{orderID}}</div>
        <div>时 长 卡： ¥{{productName}}</div>
        <div>订单金额： ¥{{totalMoney}}</div>

        <div v-if="isEnd==false">
          <div>请扫描以下二维码进行支付</div>
          <img :src="qrcode">
          <div style="color:red">剩余支付时间（超时自动关闭）：{{minutes}}分钟 {{seconds}}秒 </div>
        </div>
        <div v-if="isEnd==true">
        订单已超时,请重新下单购买
         </div>

    </div>
    <div class="airight">
    

    </div>
  </div>
  <br>  <br>  <br>  <br>  <br>
  <div class="recharge-tips">
    购买须知：
    <p>
      1、”有效期30天，“时长卡”有效期为年，自购买之日起计算，完成购买后可在“个人中心”查看明细。
    </p>

    <p>
      2、时长卡购买成功后，权益自动发放至购买账户，有效期到期后，逾期未使用的权益将自动清零。
    </p>

    <p>
      3、时长卡购买成功后，不支持转让和退款。
    </p>

    <p>
      4、若购买成功后，在“个人中心”看不到权益明细，请尝试刷新页面或退出账号重新登录。
    </p>

    <p>
      5、语音识别准确率最高97%，具体受音频质量、口音、方言等因素影响，可能导致转写准确率下降。
    </p>

    <p>
      6、灵听录音转文字有权根据会员权益的实际情况，对权益规则及内容进行调整，请注意相关通知信息。
    </p>

    <p>7、购买服务则表示您同意本站《使用协议》及《隐私协议》。</p>

    <p>
      <br> <br>
      如您有其他疑问，请联系微信客服或发邮件至xxx@163.com，进行咨询，我们将尽快为您处理。
    </p>
  </div>

  <!--底部关于我们begin-->
  <FooterHome />
  <!--底部关于我们end-->
</template>
<script>
import HeaderHome from "./HeaderHome.vue";
import FooterHome from "./FooterHome.vue";
import CommBanner2 from "./CommBanner2.vue";

import qs from "qs";

import { apiProductList, apiCreateOrderProduct } from "../utils/api.js";

import layer from "layui-layer";

export default {
  components: {
    HeaderHome,
    FooterHome,
    CommBanner2,
  },
  data() {
    return {
      lists: [],
    };
  },
  mounted() {
    apiProductList()
      .then((res) => {
        this.lists = res.data;
        //获取record信息；
        console.log(res);
      })
      .catch((res) => {
        alert("网络开小差了，请稍后再试试！");
        console.log(res);
      });
  },
  methods: {
    b(productID, productName, price) {
     /*
      this.$router.push({
                path: "/recharge_pay",
                query: { order_id: "res.data" },
              }); //成功后跳转
              */
      //未登录跳转首页
      if (!localStorage.getItem("token")) {
        this.$router.push({
          path: "/login",
          query: { record_id: "dd" },
        });
        return false;
      }
      let that = this

      let content = "<p>您即将购买以下时长卡</p>";
      content += "<p>商品：" + productName + "</p>";
      content += "<p>价格：" + price + "元";
      layer.open({
        title: "请确认购买信息",
        btn: ["确认支付"], //修改按钮文字
        type: 0, //0（信息框，默认）1（页面层）2（iframe层）3（加载层）4（tips层）
        //  area: ["300px", "160px"], //宽高
        shade: false, //不需要遮罩层，设置模态与非模态的关键
        content, //内容
        yes: function (index) {
          let post_data = qs.stringify({ product_id: productID });
          apiCreateOrderProduct(post_data)
            .then((res) => {
              layer.close(index); //关闭弹窗             
              that.$router.push({
                path: "/recharge_pay",
                query: { order_id: res.data },
              }); //成功后跳转
            })
            .catch((res) => {
              alert("网络开小差了，请稍后再试试！");
              console.log(res);
            });
        },
      });
    },
    c() {
      alert("ddd");
    },
  },
};
</script>
<style scoped>
</style>
<template >
 
  <HeaderHome />
  <!--main-->
  <CommBanner2/>
  <div class="vip-card-list">
    <div v-for="(item, index) in lists" :key="index">
      <div class="vip-card vip-right">
        <div class="vip-card-info">
          <div class="vip-card-title">{{ item.ProductName }}</div>
          <div class="vip-card-price">
            ¥{{ item.Price
            }}<span data-v-e81bd6fe="">>¥{{ item.OriPrice }}</span>
          </div>
        </div>
        <div
          class="vip-card-btn"
          @click="b(item.ProductId, item.ProductName, item.Price)"
        >
          立即购买
        </div>
      </div>
    </div>
  </div>
  <div class="recharge-tips">
    购买须知：
    <p>
      1、“时长卡”有效期为年，自购买之日起计算，完成购买后可在“个人中心”查看明细。
    </p>

    <p>
      2、时长卡购买成功后，权益自动发放至购买账户，有效期到期后，逾期未使用的权益将自动清零。
    </p>

    <p>
      3、时长卡购买成功后，不支持转让和退款。
    </p>

    <p>
      4、若购买成功后，在“个人中心”看不到权益明细，请尝试刷新页面或退出账号重新登录。
    </p>

    <p>
      5、语音识别准确率最高97%，具体受音频质量、口音、方言等因素影响，可能导致转写准确率下降。
    </p>

    <p>
      6、灵听录音转文字有权根据会员权益的实际情况，对权益规则及内容进行调整，请注意相关通知信息。
    </p>

    <p>7、购买服务则表示您同意本站《使用协议》及《隐私协议》。</p>

    <p>
      <br> <br>
      如您有其他疑问，请联系微信客服或发邮件至xxx@163.com，进行咨询，我们将尽快为您处理。
    </p>
  </div>

  <!--底部关于我们begin-->
  <FooterHome />
  <!--底部关于我们end-->

</template>
<script>
import { apiGetTransferStatus } from "../utils/api.js";
import qs from "qs";
import HeaderHome from './HeaderHome.vue';
import FooterHome from './FooterHome.vue';
import CommBanner2 from './CommBanner2.vue';


export default {
  components:{
     HeaderHome,FooterHome,CommBanner2
  },
  data() {
    return {
      tableData:[],
     // recordTitle: "",
      recordID:"",
      recordDuration:'',
      transferStatus: "",
    };
  },
  mounted() {
    let record_id = this.$route.query.record_id;
    this.recordID = record_id;
    let post_data = qs.stringify({ record_id: record_id });
    apiGetTransferStatus(post_data)
      .then((res) => {
        //this.recordTitle = res.data.title;
        //this.recordDuration=res.data.record_duration;

        let apiData = {"recordTitle":res.data.title,"recordDuration":res.data.record_duration,"transfer_status":res.data.transfer_status,"recordID":this.recordID};
        this.tableData.push(apiData);

        if(res.data.transfer_status==1){
            this.transferStatus="未开始";
        }else if(res.data.transfer_status==2){
            this.transferStatus="转写中";
        }else if(res.data.transfer_status==3){
            this.transferStatus="已完成";
        }else{
            this.transferStatus="未开始";
        }
        //获取record信息；
        console.log(res);
      })
      .catch((res) => {
        alert("网络开小差了，请稍后再试试！");
        console.log(res);
      });
  },
  methods: {
      getRecordContent(){
        this.$router.push({ 
            path: "/record_content",
            query:{record_id:this.recordID}
           }); //成功后跳转
      },
  },
};
</script>
<template >
  <HeaderHome />
  
  <CommBanner2 />
  
  <div style="margin:30px auto;width:1000px;">
    <el-steps :active="4" align-center>
      <el-step title="步骤1" description="上传音频"></el-step>
      <el-step title="步骤2" description="生成订单"></el-step>
      <el-step title="步骤3" description="支付时长卡"></el-step>
      <el-step title="步骤4" description="等待转写完成"></el-step>
      <el-step title="步骤5" description="查看/导出"></el-step>
    </el-steps>
  </div>

  <div style="width: 1000px; background: #fff; padding: 20px; margin: 0 auto;">
    <p class="subtitle">已提交转写订单</p>
    <div class="my-div">
      <el-table
      :data="tableData"
      height="100"
      :header-cell-style="{background:'#eef1f6',color:'#606266'}"
      style="width: 1000px;">
      <el-table-column type="index" width="150" label="序号" align="center"></el-table-column>
      <el-table-column
        prop="recordTitle"
        label="订单名称"
        width="250">
      </el-table-column>
      <el-table-column
        prop="recordDuration"
        label="音频时长"
        width="200">
      </el-table-column>
      <el-table-column
        label="转写状态"
        width="200">
        <template #default="scope">
          <el-tag type="info" effect="plain" v-if="scope.row.transfer_status==1 || scope.row.transfer_status==0">未开始</el-tag>
          <el-tag type="primary"  effect="plain" v-if="scope.row.transfer_status==2">转写中</el-tag>
          <el-tag type="success" effect="plain" v-if="scope.row.transfer_status==3">已转写</el-tag>
          <el-tag type="danger" effect="plain" v-if="scope.row.transfer_status==4">转写错误</el-tag>
          <el-tag type="danger" effect="plain" v-if="scope.row.transfer_status==5">转写状态异常</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="195">
        <template #default="scope">
          <el-tag type="primary" :style="{ 'cursor': 'pointer' }" effect="dark" @click="getRecordContent(scope.row.record_id)">查看</el-tag>
        </template>
      </el-table-column>

      </el-table>   
    </div>
    <div class="clear"></div>

    <div style="width: 1000px;display: block;margin:30px;text-align: center;">
        <el-button type="success" @click="apiStartTransferClick">任务已成功提交，请等待转写完成</el-button>
    </div>
  </div>

  <!--底部关于我们begin-->
  <FooterHome />
  <!--底部关于我们end-->

</template>
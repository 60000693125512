<script>
import HeaderHome from "../HeaderHome.vue";
import FooterHome from "../FooterHome.vue";
import CommBanner2 from "../CommBanner2.vue";

export default {
  components: {
    HeaderHome,
    FooterHome,
    CommBanner2,
  },
};
</script>
<template>
  <HeaderHome />
  <CommBanner2 />
  <div class="main_cont">
    <div class="c_left">
      <ul class="m-snav m-snav-order">
        <li>
          <a href="/introduce"><i></i><span>服务介绍</span></a>
        </li>
        <li  class="on">
          <a href="/question"><i></i><span>常见问题</span></a>
        </li>
      </ul>
    </div>
    <div class="c_right">
      <div class="ask_cont bg">
        <div class="toggle">
          <dl>
            <dt>
              <span class="num">1</span
              ><span class="q_title">上传音频支持哪些格式？</span
              ><span class="r_down"></span>
            </dt>
            <dd>
              <div class="answer">
                支持mp3、wav、m4a、flac、aac、ogg、wma、ape、avc、avo、opus音频格式上传。
              </div>
            </dd>

            <dt>
              <span class="num">2</span
              ><span class="q_title">音频转写有哪些限制？</span
              ><span class="r_down"></span>
            </dt>
            <dd>
              <div class="answer">
                1、因发音或背景噪音因素会影响转写准确率，请尽量提供发音标准、无背景噪音的音频文件。
                2、单个音频大小不超过2G，时长不超过5小时。
                3、音频转写会将原始音频进行统一的格式压缩，如有需要请自行保留原始音频文件。
                单文件最大支持500M，单文件时长最长不能超过5小时。【超过500M
                请用格式工厂免费软件把文件转换成MP3会小很多，<a href="http://www.pcfreetime.com/formatfactory/CN/index.html">点击下载</a>】
              </div>
            </dd>
            <dt>
              <span class="num">3</span
              ><span class="q_title">文字转语音的收费标准是什么？</span
              ><span class="r_down"></span>
            </dt>
            <dd>
              <div class="answer">充值时长会员即可使用。</div>
            </dd>
            <dt>
              <span class="num">4</span
              ><span class="q_title">多久能获取转换结果？</span
              ><span class="r_down"></span>
            </dt>
            <dd>
              <div class="answer">大约需要30秒；24小时全天候服务。</div>
            </dd>
            <dt>
              <span class="num">5</span><span class="q_title">时长卡介绍</span
              ><span class="r_down"></span>
            </dt>
            <dd>
              <div class="answer">
                1、时长卡是用于机器转写订单支付的虚拟资源，转写订单是按音频时长进行支付，以秒作为最小结算单位，精确到秒进行结算。
                2、时长卡有效期为自购买成功日起一年内，超过有效期自动作废。
                3、时长卡充值成功后，可在“我的-我的时长”查看时长使用情况。
                4、时长卡一旦充值成功，将不予转让，不予退换。
                5、通过充值时长卡支付转写的音频享受1小时音频最快5分钟出稿的高速转写。
              </div>
            </dd>
            <dt>
              <span class="num">6</span
              ><span class="q_title">音频转写失败怎么处理？</span
              ><span class="r_down"></span>
            </dt>
            <dd>
              <div class="answer">
                音频转写过程中，极小概率会因为音频文件上传过程中损坏、解码出错、网络等原因出现转写失败的情况。转写失败后，平台会即时把对应该音频的支付时长资源返还至账户，您可以查看剩余可用时长资源。
                转写失败的音频，用户可以重新上传该音频，再尝试进行转写。
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
  <FooterHome />
</template>
<style scoped>
.main_cont {
  display: flex;
  margin-top: 20px;
  margin: 0 auto;
  width: 1000px;
}
.main_cont .c_left {
  width: 150px;
  min-height: 200px;
}
.main_cont .c_right {
  flex: 1;
  min-height: 500px;
  margin-left: 20px;
}

.m-snav li a {
  display: block;
  height: 50px;
  width: 249px;
  line-height: 50px;
  text-decoration: none;
  font-size: 16px;
  color: #888;
}
.m-snav li a:hover {
  color: #333;
  font-weight: bold;
}
.m-snav li a span {
  padding-left: 25px;
  vertical-align: top;
}
.m-snav li a i {
  display: inline-block;
  height: 24px;
  width: 3px;
}
.m-snav li.on {
  background-color: rgba(0, 0, 0, 0.04);
}
.m-snav li.on a {
  font-weight: bold;
  color: #333;
}
.m-snav li.on i {
  margin: 13px 0 0 0;
  background-color: #5987ff;
}
.m-snav-order li a {
  width: 200px;
}

.toggle {
  clear: both;
}
.toggle dl dt {
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  color: #000;
  display: block;
  border-bottom: 1px solid #eee;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.toggle dl dt .q_title {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  overflow: hidden;
  height: 50px;
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
}
.toggle dl dt .num {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #5987ff;
  color: #fff;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  margin: 15px 10px 0 0;
  float: left;
  font-size: 14px;
}

.toggle dl dt.current .r_down {
  transform: rotate(180deg);
}
.toggle dl dd {
  padding: 10px 20px;
  line-height: 20px;
  background: #f1f1f1;
  color: #666;
  font-size: 14px;
  border-bottom: 1px solid #eee;
  position: relative;
  margin-bottom: 15px;
}
.toggle dl dd::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 14px solid #f1f1f1;
  position: absolute;
  left: 5px;
  top: 7px;
}
</style>
<script>
import HeaderHome from "./HeaderHome.vue";
import FooterHome from "./FooterHome.vue";
export default {
  components: {
    HeaderHome,
    FooterHome,
  },
  name: "app",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<template>
  <HeaderHome />

  <div class="index-banner">
    <div class="index-banner-box">
      <div class="index-banner-text fadeInLeft">
        <h1>专业录音转文字</h1>
        <div class="banner-text-introduce">
          <p class="p1">强大的语音识别技术</p>
          <p class="p1">支持mp3、wav、m4a等多种格式的音频文件转文字</p>
          <p class="p1">录音转文字结果支持生成txt、word、srt等多种文本格式</p>
          <a href="/audio" class="">
            <el-button class="banner-button" type="primary">立即体验</el-button>
          </a>
        </div>
      </div>
      <div class="index-banner-img fadeInRight">
        <div class="i-lottie"></div>
      </div>
    </div>
  </div>

  <div class="container2">如何进行转写</div>
  <div class="container5">
    <div class="liucheng">
      <div class="item">
        <h3>step 01</h3>
        <h1>注册账号</h1>
      </div>
      <div class="arrow">
        <img src="@/assets/images/icon-arrow.svg" alt="" />
      </div>
      <div class="item">
        <h3>step 02</h3>
        <h1>购买时长卡</h1>
      </div>
      <div class="arrow">
        <img src="@/assets/images/icon-arrow.svg" alt="" />
      </div>
      <div class="item">
        <h3>step 03</h3>
        <h1>上传音频</h1>
      </div>
      <div class="arrow">
        <img src="@/assets/images/icon-arrow.svg" alt="" />
      </div>
      <div class="item">
        <h3>step 04</h3>
        <h1>开始转写</h1>
      </div>
      <div class="arrow">
        <img src="@/assets/images/icon-arrow.svg" alt="" />
      </div>
      <div class="item">
        <h3>step 05</h3>
        <h1>在线编辑</h1>
      </div>
      <div class="arrow">
        <img src="@/assets/images/icon-arrow.svg" alt="" />
      </div>
      <div class="item">
        <h3>step 06</h3>
        <h1>下载结果</h1>
      </div>
    </div>
  </div>

  <div class="container2">特色功能</div>
  <div class="container3">
    <div class="zone">
      <div><img src="@/assets/images/index_1.png" width="60px" /></div>
      <h3>隐私保护</h3>
      <p>
        用户上传音频数据仅用于转写使用，转写完毕用户可以立即删除，如果用户未删除，本站会默认保留7天，7天之后自动删除
      </p>
    </div>
    <div class="zone">
      <div><img src="@/assets/images/index_2.png" width="60px" /></div>
      <h3>AI处理</h3>
      <p>支持mp3、wav、m4a等多种格式的音频文件转文字，转写准确率高，速度快</p>
    </div>
    <div class="zone">
      <div>
        <img
          data-v-5a558434=""
          src="@/assets/images/index_3.png"
          width="60px"
        />
      </div>
      <h3>简单方便</h3>
      <p>只需上传需要转写的音频文件,无需太多繁琐操作，简单方便</p>
    </div>
  </div>


  <FooterHome />
</template>
<style scoped>
.container2 {
  text-align: center;
  font-size: 28px;
  color: #000;
  font-weight: bold;
  margin-top: 20px;
}

.container3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  margin-bottom: 20px;
}
.container4 {
  border: 1px solid #ccc;
  padding-left: 80px;
  line-height: 2;
  width: 1300px;
  margin: 0 auto;
}

.container5 {
  padding-left: 80px;
  line-height: 2;
  width: 1300px;
  margin: 0 auto;
  height: 100px;
}

.zone {
  width: 30%;
  margin: 10px;
  /*background-color: #f1f1f1;*/
  padding: 20px;
}
.zone div {
  text-align: center;
}

.zone h3 {
  font-size: 16px;
  font-weight: bold;
  margin-top: 8px;
  text-align: center;
}

.zone p {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 0;
  color: #999;
}

.index-banner {
  background: url(@/assets/images/index_banner_bg.png) no-repeat;
  background-size: cover;
  min-height: 480px;
}

.index-banner .index-banner-box {
  margin: 0 auto;
  width: 1200px;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
}
.index-banner .index-banner-box .index-banner-text {
  width: 432px;
  margin: 0 240px 0 0;
}
.index-banner .index-banner-box .index-banner-text h1 {
  padding: 105px 0 16px;
  font-size: 48px;
  font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
  font-weight: 700;
  color: #000;
  line-height: 56px;
}
.index-banner .index-banner-box .index-banner-text .p1 {
  font-size: 16px;
  font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
  font-weight: 400;
  color: grey;
  line-height: 19px;
}
.index-banner .index-banner-box .index-banner-text .p1:not(:last-of-type) {
  margin: 0 0 23px;
}
.index-banner .index-banner-box .index-banner-text .banner-button {
  width: 200px;
  height: 50px;
  margin: 40px 0 0;
  font-size: 18px;
  font-family: Arial-Regular, Arial;
  color: #fff;
  line-height: 60px;
}

.index-banner .index-banner-box .index-banner-img {
  width: 448px;
  height: 408px;
}

.layui-container {
  position: relative;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
}

.liucheng {
  text-align: left;
}
.liucheng .item {
  width: 99px;
  height: 73px;
  float: left;
  text-align: center;
  margin: 0 0 30px;
}
.liucheng .item h3 {
  font-size: 16px;
  font-weight: bold;
  margin-top: 8px;
  text-align: center;
}
.liucheng .item h1 {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 0;
  color: #999;
}
.liucheng .arrow {
  width: 109px;
  height: 73px;
  opacity: 0.4;
  float: left;
  text-align: center;
}
.liucheng .arrow img {
  margin: 40px 29px 0;
}
</style>
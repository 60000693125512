<script>
import HeaderHome from "../HeaderHome.vue";
import FooterHome from "../FooterHome.vue";
import CommBanner2 from "../CommBanner2.vue";
import { apiFeedback } from "../../utils/api.js";
import qs from "qs";

export default {
    components: {
        HeaderHome,
        FooterHome,
        CommBanner2,
    },
    data() {
    return {
        form: {
                    suggest: '',
                    contact: '',
                },
        rules: {
                contact: [
                    { required: true, message: '请输入联系方式', trigger: 'blur' },
                    {
                        trigger: 'blur',
                        validator: (rule, value, callback) => {
                            var email = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
                            var phone = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
                            var QQ = /^\d[1-9]{5,10}$/
                            if (!email.test(value) && !phone.test(value) && !QQ.test(value)) {
                                callback(new Error('请输入正确的联系方式'))
                            }
                            callback()
                        }
                    }
                ]
            }
        };
    },
    methods:{
        apiFeedbackSubmit() {     
            if(this.form.contact==""||this.form.suggest==""){
                this.$message.error('您提交的问题未填写完整!');
                return false;
            }
            let post_data=qs.stringify({contact:this.form.contact,suggest:this.form.suggest})
            apiFeedback(post_data)
            .then((res) => {
                if (res.code == 200) {
                    this.$message.success('您的问题已提交成功，感谢您的反馈!');
                    this.$router.push({ 
                        path: "/index"
                    }); 
                }else{
                    this.$message.error('提交失败,请稍后再试!');
                    return false;
                }        
            })
            .catch((res) => {
                alert("网络开小差了，请稍后再试试！");
                console.log(res);
            });
        }
    }
  
};
</script>
<template>
    <HeaderHome />
    <CommBanner2 />
     <div id="feedbac">
        <div style="padding-left: 300px;padding-right:300px;">
            <h1 class="feedbackTitle">"极速录音转写"服务问题反馈与使用建议</h1>
            <div class="feedbackDescription">
                感谢您使用"极速录音转写"服务，您使用中遇到的任何问题都可以反馈给我们！同时，您还期望有什么功能，也请反馈给我们。
            </div>
            <div style="margin-top: 20px;display: flex;justify-content: center;">
                <el-form :model="form" label-width="100px" :rules="rules" style="width: 800px;">
                    <el-form-item label="反馈/建议：" prop="suggest">
                        <el-input v-model="form.suggest" type="textarea" :rows="8"></el-input>
                    </el-form-item>
                    <el-form-item label="联系方式：" prop="contact">
                        <el-input style="width: 200px;" v-model="form.contact" placeholder="电话/邮箱/QQ">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div style="text-align: center;margin-bottom:20px">
                <el-button type="primary" @click="apiFeedbackSubmit">提交反馈</el-button>
            </div>
        </div>
     </div>
    <FooterHome />
</template>
<style scoped>
    .feedbackTitle {
        color: #0095ff;
        font-size: 24px !important;
        font-weight: bold;
        margin: 0;
        padding: 15px 0;
        line-height: 30px;
        text-align: center;
    }

    .feedbackDescription {
        color: #555555;
        line-height: 24px;
        text-align: left;
        font-size: 16px;
        padding-bottom: 15px;
        border-bottom: 1px dashed #ccc;
        margin-left: 0;
        margin-top: 20px;
        text-indent: 1cm;
        text-align: justify
    }
</style>
<script>
import HeaderHome from '../HeaderHome.vue';
import FooterHome from '../FooterHome.vue';
import CommBanner2 from '../CommBanner2.vue';

export default {
    components:{
        HeaderHome,FooterHome,CommBanner2
    },
};
</script>
<template>
    <HeaderHome />
    <CommBanner2 />

    <div class="main_cont">
        <div class="c_left">
            <ul class="m-snav m-snav-order">
                <li class="on"><a href="/introduce"><i></i><span>服务介绍</span></a></li>
                <li><a href="/question"><i></i><span>常见问题</span></a></li>
            </ul>
        </div>
        <div class="c_right">
                <div class="intro_content">
                    极速录音转写，是基于语音识别、语音合成、自然语言理解等技术，为企业在多种实际应用场景下语音转文字，用户完成上传音频，等待机器转写完成，可以对转写结果进行在线编辑，之后一键导出。适用于多个应用场景中，包括智能问答、智能质检、法庭庭审实时记录、实时演讲字幕、访谈录音转写等场景，在视频制作、企业宣传、自媒体，广告叫卖，彩铃配音，无障碍阅读，金融、保险、司法、电商等多个领域！
<br><br>
                    <p>只需要4步即可完成一次转写（注册账号、购买时长卡、上传音频、开始转写），操作过程简单方便。</p>
                </div>

        </div>
    </div>
    <FooterHome />

</template>
<style scoped>

.main_cont {
  display: flex;
  margin-top: 20px;
  margin: 0 auto;
  width: 1000px;
}
.main_cont .c_left {
  width: 150px;
  min-height: 200px;
}
.main_cont .c_right {
  flex: 1;
  min-height: 500px;
  margin-left: 20px;
}

.m-snav li a {
  display: block;
  height: 50px;
  width: 249px;
  line-height: 50px;
  text-decoration: none;
  font-size: 16px;
  color: #888;
}
.m-snav li a:hover {
  color: #333;
  font-weight: bold;
}
.m-snav li a span {
  padding-left: 25px;
  vertical-align: top;
}
.m-snav li a i {
  display: inline-block;
  height: 24px;
  width: 3px;
}
.m-snav li.on {
  background-color: rgba(0, 0, 0, 0.04);
}
.m-snav li.on a {
  font-weight: bold;
  color: #333;
}
.m-snav li.on i {
  margin: 13px 0 0 0;
  background-color: #5987ff;
}
.m-snav-order li a {
  width: 200px;
}
.intro_content {
    margin-top: 10px;
    text-indent: 2em;

}

</style>
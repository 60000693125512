<template>
  

  附录-第三方信息共享清单
  <br><br>
中国移动SDK
<br>
第三方主体：中移互联网有限公司 
<br>
共享的信息：网络类型、网络地址、运营商类型、本机号码、手机设备类型、手机操作系统、硬件厂商、设备标识、网络制式、、浏览器类型、浏览器版本号、浏览器是否启动Cookie、CPU等级、CPU虚拟核心数、系统语言、浏览器插件、屏幕可用宽度、屏幕可用高度、颜色质量、时差区）
<br>
使用场景：用户登录时使用
<br>
使用目的：为实现一键登录/本机号码校验的功能
<br>
共享方式：SDK本机采集，不涉及数据传输共享
<br>
第三方隐私政策或官网链接：<a href="https://wap.cmpassport.com/resources/html/contract2.html">点击查看</a>

<br><br>
中国联通SDK
<br>
第三方主体：中国联合网络通信有限公司
<br>
共享的信息：网络类型、网络地址、运营商类型、本机手机号、手机设备类型、手机操作系统、硬件厂商
<br>
使用场景：用户登录时使用
<br>
使用目的：为实现一键登录/本机号码校验的功能
<br>
共享方式：SDK本机采集，不涉及数据传输共享
<br>
第三方隐私政策或官网链接：<a href="https://opencloud.wostore.cn/authz/resource/html/disclaimer.html?fromsdk=true">点击查看</a>

<br><br>
中国电信SDK
<br>
第三方主体：世纪龙信息网络有限责任公司
<br>
共享的信息：网络IP地址、网络类型；改变网络连接状态的权限、访问蜂窝数据网络和Wi-Fi网络的权限
<br>
使用场景：用户登录时使用
<br>
使用目的：为实现一键登录/本机号码校验的功能
<br>
共享方式：SDK本机采集，不涉及数据传输共享
<br>
第三方隐私政策或官网链接：<a href="https://e.189.cn/sdk/agreement/show.do?order=2&type=main&appKey=&hidetop=&returnUrl=">点击查看</a>

<br><br>
微信Open SDK
<br>
第三方主体：深圳市腾讯计算机系统有限公司
<br>
共享的信息：微信支付订单标识、用户设备上安装微信APP的状态（仅限Andorid版本）
<br>
使用场景：用户发起支付时使用
<br>
使用目的：为实现使用微信支付的功能
<br>
共享方式：SDK本机采集，不涉及数据传输共享
<br>
第三方隐私政策或官网链接：<a href="https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8">点击查看</a>

</template>
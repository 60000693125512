<script>
import qs from "qs";
import { apiUserReg } from "../utils/api.js";
import HeaderHome from './HeaderHome.vue';
import FooterHome from './FooterHome.vue';
import CommBanner2 from './CommBanner2.vue';


export default {
  name: "UserLogin",
  components:{
     HeaderHome,FooterHome,CommBanner2
  },
  data() {
    return {
        regForm: {
            tel: "",
            username: "",
            password: "",
            password_check: "",
            email: "",
      },
    };
  },
  methods: {
    toRegister() {
      //表单验证
      if (this.regForm.tel == "" || this.regForm.username == "" || this.regForm.password == "" || this.regForm.password_check == "" || this.regForm.email == "") {
        this.$message({
          message: "请输入用户名",
          type: "error",
        });
        return;
      }
      if (this.regForm.password !== this.regForm.password_check) {
        this.$message({
          message: "两次输入的密码不同",
          type: "error",
        });
        return;
      }
      //发送登陆请求
      let post_data = qs.stringify({
        tel: this.regForm.tel,
        username: this.regForm.username,
        password: this.regForm.password,
        password_check: this.regForm.password_check,
        email: this.regForm.email,
      });
      if (this.regForm.tel != "" && this.regForm.username != "" && this.regForm.password != "" && this.regForm.password_check != "" && this.regForm.email != "") {
        apiUserReg(post_data)
          .then((res) => {
            if (res.code == 200) {
              alert("注册成功，请登陆！");
              this.$router.push("/login");
            } else {
              alert("注册失败");
            }
          })
          .catch((res) => {
            alert("网络开小差了，请稍后再试！");
            console.log(res);
          });
      }
    },
  },
};
</script>
<template>
  <HeaderHome />
  <CommBanner2 />

  <div class="rg_layout">
    <div class="rg_left">
        <p>新用户注册</p>
        <p>USER REGISTER</p>
    </div>
    <div class="rg_center">
        <div class="rg_form">
            <!--定义表单 form-->
                <table>
                    <tr>
                        <td class="td_left"><label for="tel">手机号</label></td>
                        <td class="td_right"><input type="text" name="phone" id="phone" v-model.trim="regForm.tel" placeholder="请输入手机号"></td>
                    </tr>
                    <tr>
                        <td class="td_left"><label for="tel">验证码</label></td>
                        <td class="td_right"><input type="text" name="code" id="code" v-model.trim="regForm.code" placeholder="请输入验证码"></td>
                    </tr>
                    <tr>
                        <td class="td_left"><label for="username">用户名</label></td>
                        <td class="td_right"><input type="text" name="username" id="username" v-model.trim="regForm.username" placeholder="请输入用户名"></td>
                    </tr>

                    <tr>
                        <td class="td_left"><label for="password">密码</label></td>
                        <td class="td_right"><input type="password" name="password" id="password" v-model.trim="regForm.password" placeholder="请输入密码"></td>
                    </tr>
                    <tr>
                        <td class="td_left"><label for="password">再次输入密码</label></td>
                        <td class="td_right"><input type="password" name="password_check" id="password_check" v-model.trim="regForm.password_check" placeholder="请再次输入密码"></td>
                    </tr>
                    <tr>
                        <td class="td_left"><label for="email">Email</label></td>
                        <td class="td_right"><input type="email" name="email" id="email" v-model.trim="regForm.email" placeholder="请输入邮箱"></td>
                    </tr>
                </table>
        </div>
        <div class="btn_center"><button type="button" id="btn_sub" @click="toRegister">注 册</button></div>

    </div>
    <div class="rg_right">
        <p>已有账号?<a href="/login">立即登录</a></p>
    </div>
</div>
  <FooterHome />
</template>

<style  scoped>
    *{
             margin: 0px;
             padding: 0px;
             box-sizing: border-box;
         }
      
         .rg_layout{
             width: 1000px;
             height: 500px;
             border: 8px solid #EEEEEE;
             background-color: white;
             /*让div水平居中*/
             margin: auto;
             margin-top: 15px;
             margin-bottom: 15px;
         }
         .rg_left{
             /*border: 1px solid red;*/
             float: left;
             margin: 15px;
         }
         .rg_left > p:first-child{
             color: #FFD026;
             font-size: 20px;
         }
         .rg_left > p:last-child{
             color: #A6A6A6;
             font-size: 20px;
         }
         .rg_center{
             /*border: 1px solid red;*/
             float: left;
             width: 600px;
         }
         .rg_form{
           margin: auto;
           margin-top: 50px;
           margin-left: 100px;
         }
         .rg_right{
             /*border: 1px solid red;*/
             float: right;
             margin: 15px;
         }
         .rg_right > p:first-child{
             font-size: 15px;
         }
         .rg_right p a{
             color: pink;
         }
         .td_left{
             width: 100px;
             text-align: right;
             height: 45px;
         }
         .td_right{
             padding-left: 50px;
         }
         #username,#password,#password_check,#code,#email,#name,#phone,#birthday{
             width: 251px;
             height: 32px;
             border: #A6A6A6 1px solid;
             /*设置圆角边框*/
             border-radius: 5px;
             padding-left: 10px;
         }
         #checkcode{
             width: 110px;
         }
         #img_check{
             height: 32px;
             vertical-align: middle;
         }
         .btn_center{
           margin: auto;
           margin-top: 20px;
           margin-left: 160px;
         }
         #btn_sub{
             width: 150px;
             height: 40px;
             background-color: dodgerblue;
             border: #cccccc 1px solid;
             cursor: pointer;
             color: #ffffff;
             left: 50%;  
             margin-left: 100px;
         }
 </style>
import { createRouter, createWebHistory } from "vue-router";
import IndexHome from "@/view/IndexHome";
import UserLogin from "@/view/UserLogin";
import UserReg from "@/view/UserReg";
import MemberCenter from "@/view/MemberCenter";

import AudioUpload from "@/view/AudioUpload";
import AudioInfo from "@/view/AudioInfo";
import ConfirmOrder from "@/view/ConfirmOrder";
import OrderInfo from "@/view/OrderInfo";
import RecordList from "@/view/OrderList";
import RecordContent from "@/view/RecordContent";
import ReCharge from "@/view/ReCharge";
import ReChargePay from "@/view/ReChargePay";

import UserProtocol from "@/view/UserProtocol";
import UserPrivacy from "@/view/UserPrivacy"; 
import UserPrivacyThird from '@/view/UserPrivacyThird'

import AboutUs from "@/view/AboutAll/AboutUs";
import ServiceIntroduce from "@/view/HelpCenter/ServiceIntroduce";
import CommonProblem from "@/view/HelpCenter/CommonProblem";
import FeedBack from "@/view/HelpCenter/FeedBack";


// 3 创建路由对象
const router = createRouter({
  // 3.1 通过 history 属性，指定路由的工作模式
  history: createWebHistory(),
  // 3.2 通过 routes 数组，指定路由规则
  // path 是 hash 地址，component 是要展示的组件
  routes: [
   
    {
      path: "/index.html",
      redirect:"/index",
    },
 
    {
      path: "/",
      redirect:"/index",
    },
    
    {
      path: "/index",
      name: "IndexHome",
      component: IndexHome, //和上面import的AudioUpload对应
      //  component:()=>AudioUpload
    },
    {
      path: "/login",
      name: "UserLogin",
      component: UserLogin, //和上面import的AudioUpload对应
      //  component:()=>AudioUpload
    },
    {
      path: "/reg",
      name: "UserReg",
      component: UserReg, //和上面import的AudioUpload对应
      //  component:()=>AudioUpload
    },
    {
      path: "/member",
      name: "MemberCenter",
      component: MemberCenter, //和上面import的AudioUpload对应
      //  component:()=>AudioUpload
    },
    {
      path: "/audio",
      name: "AudioUpload",
      component: AudioUpload, //和上面import的AudioUpload对应
      meta: { requireAuth: true },
      //  component:()=>AudioUpload
    },
    {
      path: "/audio_info",
      name: "AudioInfo",
      component: AudioInfo, //和上面import的AudioUpload对应
      meta: { requireAuth: true },
      //component:()=>ConfirmOrder
    },
    {
      path: "/confirm",
      name: "ConfirmOrder",
      component: ConfirmOrder, //和上面import的AudioUpload对应
      meta: { requireAuth: true },
      //component:()=>ConfirmOrder
    },
    {
      path: "/record",
      name: "OrderInfo",
      component: OrderInfo, //和上面import的AudioUpload对应
      meta: { requireAuth: true },

      //component:()=>ConfirmOrder
    },
    {
      path: "/record_list",
      name: "RecordList",
      component: RecordList, //和上面import的AudioUpload对应
      meta: { requireAuth: true },

      //component:()=>ConfirmOrder
    },
    {
      path: "/record_content",
      name: "RecordContent",
      component: RecordContent, //和上面import的AudioUpload对应
      meta: { requireAuth: true },

      //component:()=>ConfirmOrder
    },
    {
      path: "/recharge",
      name: "ReCharge",
      component: ReCharge, //和上面import的AudioUpload对应
      meta: { requireAuth: false },

      //  component:()=>AudioUpload
    },
    {
      path: "/recharge_pay",
      name: "ReChargePay",
      component: ReChargePay, //和上面import的AudioUpload对应
      meta: { requireAuth: false },

      //  component:()=>AudioUpload
    },

    //关于我们
    {
      path: "/about_us",
      name: "AboutUs",
      component: AboutUs, //和上面import的AudioUpload对应
      //  component:()=>AudioUpload
    },
    //服务协议
    {
      path: "/protocol",
      name: "UserProtocol",
      component: UserProtocol, //和上面import的AudioUpload对应
      //  component:()=>AudioUpload
    },
     //隐私协议
     {
      path: "/privacy",
      name: "UserPrivacy",
      component: UserPrivacy, //和上面import的AudioUpload对应
      //  component:()=>AudioUpload
    },
    //隐私协议-第三方
    {
      path: "/privacy_third",
      name: "UserPrivacyThird",
      component: UserPrivacyThird, //和上面import的AudioUpload对应
      //  component:()=>AudioUpload
    },
    // 帮助中心-服务介绍
     {
      path: "/introduce",
      name: "ServiceIntroduce",
      component: ServiceIntroduce, //和上面import的AudioUpload对应
    },
    // 帮助中心-常见问题
    {
      path: "/question",
      name: "CommonProblem",
      component: CommonProblem, //和上面import的AudioUpload对应
    },
    // 帮助中心-在线反馈
    {
      path: "/feedback",
      name: "FeedBack",
      component: FeedBack, //和上面import的AudioUpload对应
    },

  ],
});
/*
let token='';
if(localStorage.getItem("token")){
  token = localStorage.getItem("token");
  store.commit("setToken",localStorage.getItem("token"));
  console.log("路由打印");
  console.log(token);
}
console.log("====");
console.log(token);
*/
router.beforeEach((to, from, next) => {
  let token = "";
  if (localStorage.getItem("token")) {
    token = localStorage.getItem("token");
  }
  //  store.commit("setToken",localStorage.getItem("token"));
  console.log("跳转路由前的的token判断:" + token);
  if (token) {
    console.log("跳转路由");

    next();
  } else {
    if (
      to.path === "/" ||
      to.path === "/index" ||
      to.path === "/index.html" ||
      to.path === "/about_us" ||
      to.path === "/reg" ||
      to.path === "/login" ||
      to.path === "/recharge"||
      to.path === "/question" ||
      to.path === "/introduce" ||
      to.path === "/feedback"||
      to.path === "/protocol" || 
      to.path === "/privacy" ||
      to.path === "/privacy_third"
    ) {
      next();
    } else {
      console.log("没有token");
      //// console.log(token);
      next("/login");
    }
  }
});
// 4、向外共享路由对象
export default router;

<template>
  <div class="footer">
    <div class="w1260">
      <img :src="require('@/assets/images/foot_logo.png')" class="l" />

      <div class="w800 r">
        <ul>
          <li>
            <p>关于我们</p>
            <a href="/about_us">关于我们</a>
            <a href="/about_us">联系我们</a>
          </li>
          <li>
            <p>会员相关</p>
            <a href="/member" target="_blank" rel="nofollow">会员中心</a>
            <a href="/record_list" target="_blank" rel="nofollow">我的转写</a>
          </li>
          <li>
            <p>帮助中心</p>
            <a href="/protocol" target="_blank" rel="nofollow">服务协议</a>
            <a href="/privacy" target="_blank" rel="nofollow">隐私协议</a>
            <a href=" " target="_blank" rel="nofollow">常见问题</a>
          </li>
          <li>
            <p>客户咨询</p>
            <div>
              工作时间：08:30-17:30 <br />
              法定节假日请留言
            </div>
            <a
              href="http://wpa.qq.com/msgrd"
              target="_blank"
              rel="nofollow"
              class="qq"
            >
              <img src="@/assets/images/qq.jpg" />
              联系客服
            </a>
          </li>
        </ul>
      </div>

      <div class="clear"></div>
    </div>

    <p>
      版权所有 <span id="copyright-footer"></span> 极速语音转写
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >ICP备案号：京ICP备111111111号</a
      >
    </p>
  </div>
</template>
<script>
export default {
  mounted() {
    // 页脚年份实时更新
    let p = document.getElementById("copyright-footer");
    p.innerText = `Copyright © ${this.getNowYear()}`;
  },
  methods: {
    getNowYear() {
      let date = new Date();
      return date.getFullYear();
    },
  },
};
</script>
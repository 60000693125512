<script>
    /*import tab1 from './components/tabs/tab1.vue'
    import tab2 from './components/tabs/tab2.vue'*/

    import { apiTimeCardUseDetail,apiGetAssetAll } from "../utils/api.js";

    import HeaderHome from './HeaderHome.vue';
    import FooterHome from './FooterHome.vue';
    import CommBanner2 from './CommBanner2.vue';
    export default {
        components:{
            HeaderHome,FooterHome,CommBanner2
        },
        name: 'app',
        data(){
          return {
            showAssets: true,
            showUsageDetail: false,
            lists: [],
            assets_lists: [],
          };
        },
        mounted() {
            apiTimeCardUseDetail()
            .then((res) => {
              this.lists = res.data;
            })
            .catch((res) => {
                alert("网络开小差了，请稍后再试！");
                console.log(res);
            });

            apiGetAssetAll()
            .then((res2) => {
              this.assets_lists = res2.data;
            })
            .catch((res2) => {
                alert("网络开小差了，请稍后再试！");
                console.log(res2);
            });
        },
        methods: {
          /*
            change(index){
                this.tab.forEach(function(v){
                v.isactive=false
            })
            this.tab[index].isactive=true
           
        } */
          showAssetsClick() {
            this.showAssets = true;
            this.showUsageDetail = false;
          },
          showAssetsDetailClick() {
            this.showUsageDetail = true;
            this.showAssets = false;
          }
      }
    }
</script>
<template>
  <HeaderHome />

  <CommBanner2/>
   
  <div style="width: 1000px; background: #fff; padding: 20px;margin: 0 auto;">
    <p class="subtitle"> 
      <a style="cursor:pointer;" @click="showAssetsClick">我的时长卡</a> | <a style="cursor:pointer;" @click="showAssetsDetailClick">我的时长使用明细</a> 
    </p>
    
    <div class="my-div" v-if="showAssets">
      <el-table
        :data="assets_lists"
        height="600"
        :header-cell-style="{background:'#eef1f6',color:'#606266'}"
        style="width: 980px;">
        <el-table-column type="index" width="200" label="序号" align="center"></el-table-column>
        <el-table-column
          prop="create_time"
          label="购买时间"
          width="270">
        </el-table-column>
        <el-table-column
          prop="time_card_name"
          label="时长卡"
          width="270">
        </el-table-column>
        <el-table-column
          prop="value_left"
          label="剩余时长"
          width="250">
        </el-table-column>
      </el-table>
    </div>

    <div class="my-div" v-if="showUsageDetail">
      <el-table
        :data="lists"
        height="600"
        :header-cell-style="{background:'#eef1f6',color:'#606266'}"
        style="width: 980px;">
        <el-table-column type="index" width="200" label="序号" align="center"></el-table-column>
        <el-table-column
          prop="time_card_name"
          label="时长卡"
          width="195">
        </el-table-column>
        <el-table-column
          prop="used_value"
          label="使用明细"
          width="200">
        </el-table-column>
        <el-table-column
          prop="transfer_order_id"
          label="关联订单"
          width="195">
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="使用时间"
          width="195">
        </el-table-column>
      </el-table>
    </div>
  </div>      

  <FooterHome />

</template>
          
<style>
    *{
      padding:0;
      margin:0;
      box-sizing:border-box;
    }

    .my-div {
      margin: 0 auto;
      border: 1px solid #ddd; /* 设置边框的大小、类型和颜色 */
      width: 980px;
    } 
</style>
    
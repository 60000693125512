<template>
  <div class="user_top" id="user_top">
    <div class="dropdown">
      <a href="/member" title="进入会员中心">你好，{{ userName }}</a>
      <ul class="dropdown-menu">
        <li><a href="/member">会员中心</a></li>
      </ul>
    </div>
    <span class="user_logout" @click="logout">【退出登陆】</span>
    <!--  <el-button class="user_logout" type="text" @click="logout">【退出登陆】</el-button> -->
  </div>
</template>

<style scoped>
.user_top {
  color: #666;
  padding-top: 8px;
  text-align: right;
  padding-top: 25px;
}
.user_top a {
  color: #666;
}
.user_logout {
  color:#409EFF;
  cursor: pointer;
}

.dropdown {
  list-style: none;
  float: left;
  margin-right: 5px;
  display: block;
  width: 150px;
}

.dropdown:hover {
  background-color: white;
  border-radius: 5px 5px 0 0;
  /* padding-top: 5px;*/
}

.dropdown:hover .dropdown-menu {
  border: 1px solid #f7ecb5;
  border-radius: 5px;
  padding: 5px;
  width: 150px;
  margin-top: 8px;
}

.dropdown:hover .dropdown-menu li {
  list-style: none;
  line-height: 30px;
  display: block;
}

.dropdown-menu li {
  display: none;
  background-color: transparent;
  text-align: center;
}

.dropdown-menu > li:hover {
  background-color: #ddd;
}

.dropdown-menu > li:hover a {
  color: white;
  font-weight: bold;
}
</style>
<script>
import store from "../store/store";

export default {
  name: "UserInfo",
  data() {
    return {
      userName: "",
    };
  },
  created() {},
  mounted() {
    this.userName = store.getters.getUser;
    if (this.userName == "") {
      let p = document.getElementById("user_top");
      p.innerHTML = `您还未登陆! <a href="/login">【登陆】</a> | <a href="/reg">【去注册】</a> `;
    }
  },
  methods: {
    logout() {
      this.$confirm("请确认是否要退出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 第一个参数为key的名，第二个参数为空
          store.commit("clearToken");
          store.commit("clearUserName");
          //      alert("退出登陆");

          let p = document.getElementById("user_top");
          p.innerHTML = `您还未登陆! <a href="/login">【登陆】</a> | <a href="/reg">【去注册】</a> `;

          setTimeout(() => {
            this.$router.push("/login");
            this.$router.go(0);
          }, 0);
          this.$message({
            type: "success",
            message: "退出成功!",
          });
        })
        .catch(() => {
          /*
          this.$message({
            type: "info",
            message: "暂不退出",
          });
          */
        });

      //localStorage.removeItem("token")
      ///localStorage.setItem("token", "")
    },
  },
};
</script>

<template>
    <div class="comm_banner2">
        <div class="comm_banner2_text">
			<h1>专业录音转文字</h1>
			<div class="banner-text-introduce">
				<p class="comm_banner2_text p1">强大的语音识别技术</p>
				<p class="comm_banner2_text p1">支持mp3、wav、m4a等多种格式的音频文件转文字</p>
				<p class="comm_banner2_text p1">录音转文字结果支持生成txt、word、srt等多种文本格式</p>
			</div>
		</div>
    </div>
</template>

<style scoped>
.comm_banner2 {
    /*background:url(@/assets/images/index_banner_bg.png) no-repeat;*/
	background-size:cover;
	min-height:200px;
    /*background-color: #2299FF;*/
    background-color: #3366FF;
}

.comm_banner2_text {
    color:#FFF;
    text-align: center;
}

.comm_banner2_text h1 {
	padding:15px 0 16px;
	font-size:38px;
	font-family:Microsoft YaHei UI-Bold,Microsoft YaHei UI;
	font-weight:bold;
	color:#FFF;
	line-height:56px
}

.comm_banner2_text .p1 {
    font-size:16px;
	font-family:Microsoft YaHei UI-Regular,Microsoft YaHei UI;
	font-weight:400;
	color:#fff;
	line-height:19px;
	margin-bottom:20px;
}
</style>
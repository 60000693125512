<template>
  <HeaderHome />
   <!--main-->
 
   <CommBanner2/>
 
   <div style="margin:30px auto;width:1000px;">
    <el-steps :active="1" align-center>
      <el-step title="步骤1" description="上传音频"></el-step>
      <el-step title="步骤2" description="生成订单"></el-step>
      <el-step title="步骤3" description="支付时长卡"></el-step>
      <el-step title="步骤4" description="等待转写完成"></el-step>
      <el-step title="步骤5" description="查看/导出"></el-step>
    </el-steps>
  </div>

   <div class="aicenter">
 
   <el-form :model="form" label-width="120px">
   <el-form-item label="订单名称：">
     <el-col :span="5">
       <el-input v-model="form.title"  placeholder="请输入内容"></el-input>
     </el-col>
   </el-form-item>
   
   <el-form-item label="上传音频：">
     <el-col :span="8">
     <el-upload
       drag 
       ref="upload"
       action=""
       :class="{'hide2':hideUploadSelectedBtn}"
       :file-list="fileList"
       :auto-upload="false"
       :http-request="uploadFile"
       :on-change="handleChange"
       :on-preview="handlePreview"
       :on-remove="handleRemove"
       :limit="1"
     >
     <el-button size="small" type="primary" @click="delFile">选取文件</el-button>
     </el-upload>
 
     <el-button
         v-if = "showUploadBtn"
         style="margin-left: 10px;"
         size="small"
         type="primary"
         @click="submitUpload"
       >上传云端</el-button>
     </el-col>
 
     <el-input v-model="form.audio_url" hidden style="display:none"></el-input>
     <el-input v-model="form.audio_duration" hidden style="display:none"></el-input>
   </el-form-item>
 
   <el-form-item>
     <el-button type="primary" @click="apiCreateRecordClick">生成订单</el-button>
   </el-form-item>
 
   </el-form>
   
 </div>
     <FooterHome />  
 </template>
   
 <script>
 import HeaderHome from './HeaderHome.vue';
 import FooterHome from './FooterHome.vue';
 import CommBanner2 from './CommBanner2.vue';
 import { apiUpload, apiCreateRecord } from "../utils/api.js";
 
 import qs from 'qs'
 
 //import axios from "axios";
 export default {
   components:{
      HeaderHome,FooterHome,CommBanner2
   },
   name: "AddFile",
   data() {
     return {
       form:{
          title:"",
          audio_url:"",
          audio_duration:"",
       },
 
 
       fileList: [],
       formData: "",
       hideUploadSelectedBtn: false, // 选择按钮
       showUploadBtn: false, // 隐藏上传按钮
       limitCount:1,
       fileLegal:true,
       fileSizeExceed:false,
 
     };
   },
   mounted() {
  
 
   },
   methods: {
     delFile() {
       this.fileList = [];
     },
      // 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
     //beforeUploadFile(file) {},
     // auto-upload is false,before-upload不生效
     handleChange(file, fileList) {
       let extension = file.name.substring(file.name.lastIndexOf('.')+1);
       let size = file.size / 1024 / 1024;
       if(extension !== 'mp3' && extension !== 'mp4' && extension !== 'wav' && extension !== 'pcm' && extension !== 'm4a') {
         this.$message.warning('只能上传后缀是mp3、mp4、wav、pcm、m4a的文件');
         this.fileLegal = false;
         return false;
       }
       // 限制上传大小500M
       if(size > 500) {
         this.$message.warning('文件大小不得超过10M');
         this.fileSizeExceed = true;
         return false;
       }
       this.fileList = fileList;
       this.hideUploadSelectedBtn = fileList.length >= this.limitCount;
       this.showUploadBtn = true;
     },
     uploadFile(file) {
       this.formData.append("file", file.file);
     },
     // 移除已上传的文件
     handleRemove(file, fileList) {
       this.hideUploadSelectedBtn = fileList.length >= this.limitCount;
     },
     handlePreview(file) {
       console.log(file);
     },
     // 上传处理
     submitUpload() {
       if (this.fileList.length === 0){
           this.$message.warning('请上传文件');
       }
       // 文件格式不合法
       if (!this.fileLegal){
         this.$message.warning('只能上传后缀是mp3、mp4、wav、pcm、m4a的文件');
         return false;
       }
       if (this.fileSizeExceed){
         this.$message.warning('只能上传后缀是mp3、mp4、wav、pcm、m4a的文件');
         return false;
       }
       let formData = new FormData();
       formData.append("theme", this.theme);
       formData.append("file", this.fileList[0].raw);
 
       apiUpload(formData)
         .then((res) => {
           if (res.code == 200) {
             this.$message.success('上传成功！');
             this.showUploadBtn = false;
             this.form.audio_url=res.data.path;
             this.form.audio_duration=res.data.audio_duration;
           } else {
             this.$message.error('上传失败');
           }                         
         })
         .catch((res) => {
           this.$message.error('网络开小差了，请稍后再试试！');
           console.log(res);
         });
     },
     // 生成订单处理
     apiCreateRecordClick() {
       //判断参数是否为空
       if (this.form.title == "" || this.form.title == null) {
        alert("请填写订单名称", { icon: 5 });
         //  layer.msg("请填写订单名称",{icon:5});
       //  $("#title").focus();
         return false;
       }
 
       if (this.form.audio_url == "") {
         alert("请先上传音频", { icon: 5 });
         return false;
       }
 
       //testPing()
      let post_data=qs.stringify({title:this.form.title,audio_url:this.form.audio_url,audio_duration:this.form.audio_duration})
       apiCreateRecord(post_data)
         .then((res) => {
           this.$router.push({ 
             path: "/audio_info",
             query:{record_id:res.data.record_id}
            }); //成功后跳转
         })
         .catch((res) => {
           alert("网络开小差了，请稍后再试试！");
           console.log(res);
         });
     },
   }
 };
 </script>
   
 <style  scoped>
   ::v-deep .hide2 .el-upload-dragger {
     display: none;
   }
 </style>
import { createStore } from 'vuex'
export default createStore({
        state:{
        token:"",
        userName:""
       },
       getters:{
            getToken(state){
                return state.token || localStorage.getItem("token")||"";
            },
            getUser(state){
                return state.userName || localStorage.getItem("userName")||"";
            }
        },
        mutations:{
            setToken(state,token){
                state.token =token;
                localStorage.setItem('token',token);
                console.log('保存成功'+token);
            },
            delToken(state){
                state.token ="";
                localStorage.removeItem("token");
            },
            clearToken(state){
                state.token ="";
                localStorage.clear();
            },
            setUserInfo(state,userName){
                state.userName=userName;
                localStorage.setItem('userName',userName);
                console.log('保存成功'+userName);
            },
            delUserName(state){
                state.userName ="";
                localStorage.removeItem("userName");
            },
            clearUserName(state){
                state.userName ="";
                localStorage.clear();
                localStorage.removeItem("userName");

            },

        },
        actions:{
    
        },
})


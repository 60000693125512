// 刚刚封装的axios
import request from './axios';
/*
// 1.获取图片验证码
export const getImgCode = () => {
    return request({
        url: '/image/code',
        method: 'get',
        // 图片验证码 response类型设置成blob，图片才能显示出来
        responseType: "blob"
    })
}
*/
export const testPing = () => {
    return request({
        url: '/ai/v1/ping',
        method: 'get',
 
    })
}

/*
// 2.获取手机验证码
export const getPhoneCode = (query) => {
    alert("bbb");
    return request({
        url: '/user/messageCode',
        method: 'post',
        data: query
    })
}
*/
// 3. 创建record
export const apiCreateRecord = (params) => {
    return request({
        url: '/ai/v1/create_record',
        method: 'post',
        data:params,
    })
}
// 4. 根据record获取音频信息
export const apiGetRecord = (params) => {
    return request({
        url: '/ai/v1/get_record',
        method: 'post',
        data:params,
    })
}
// 5. 开始转写
export const apiStartTransfer = (params) => {
    return request({
        url: '/ai/v1/start_transfer',
        method: 'post',
        data:params,
    })
}
export const apiCreateTransferOrder = (params) => {
    return request({
        url: '/ai/v1/create_transfer_order',
        method: 'post',
        data:params,
    })
}
// 6. 获取转写状态
export const apiGetTransferStatus = (params) => {
    return request({
        url: '/ai/v1/get_transfer_status',
        method: 'post',
        data:params,
    })
}

export const apiRecordContent = (params) => {
    return request({
        url: '/ai/v1/get_record_content',
        method: 'post',
        data:params,
    })
}

export const apiRecordList = (params) => {
    return request({
        url: '/ai/v1/record_list',
        method: 'post',
        data:params,
    })
}

export const apiProductList = (params) => {
    return request({
        url: '/ai/v1/product_list',
        method: 'post',
        data:params,
    })
}

export const apiCreateOrderProduct = (params) => {
    return request({
        url: '/ai/v1/product_order',
        method: 'post',
        data:params,
    })
}

export const apiGetOrderInfo = (params) => {
    return request({
        url: '/ai/v1/product_order_info',
        method: 'post',
        data:params,
    })
}
export const apiGetAssets = (params) => {
    return request({
        url: '/ai/v1/assets/get',
        method: 'post',
        data:params,
    })
}

// 确认订单转写
export const apiTransferPay = (params) => {
    return request({
        url: '/ai/v1/transfer_pay',
        method: 'post',
        data:params,
    })
}
/*
// 4. 搜索任务接口
export const queryTask = (query1, query2) => {
    return request({
        url: '/task/query',
        method: 'post',
        data: query1,
        params: query2
    })
}
*/

// 用户-登陆
export const apiUserLogin = (params) => {
    return request({
        url: '/ai/v1/login',
        method: 'post',
        data:params,
    })
}
// 用户-注册
export const apiUserReg = (params) => {
    return request({
        url: '/ai/v1/reg',
        method: 'post',
        data:params,
    })
}
// 时长卡明细
export const apiTimeCardUseDetail = (params) => {
    return request({
        url: '/ai/v1/time_card_get',
        method: 'post',
        data:params,
    })
}
// 所有时长卡
export const apiGetAssetAll = (params) => {
    return request({
        url: '/ai/v1/get_assets_all',
        method: 'post',
        data:params,
    })
}

// 获取时长卡购买订单状态
export const apiGetPayStatus = (params) => {
    return request({
        url: '/ai/v1/pay_status',
        method: 'post',
        data:params,
    })
}

// 关闭时长卡购买订单
export const apiCloseTimecardOrder = (params) => {
    return request({
        url: '/ai/v1/close_timecard_order',
        method: 'post',
        data:params,
    })
}

// 上传文件
export const apiUpload = (params) => {
    return request({
        url: '/ai/v1/upload',
        method: 'post',
        data:params,
        headers:{'Content-Type': 'multipart/form-data'},
    })
}

// 上传文件
export const apiFeedback = (params) => {
    return request({
        url: '/ai/v1/feedback',
        method: 'post',
        data:params,
    })
}


<script>
import { apiGetRecord, apiCreateTransferOrder,apiGetAssets } from "../utils/api.js";
import qs from "qs";
import HeaderHome from './HeaderHome.vue';
import FooterHome from './FooterHome.vue';
import CommBanner2 from './CommBanner2.vue';

export default {
  components:{
     HeaderHome,FooterHome,CommBanner2
  },
  data() {
    return {
      //recordTitle: "",
      //recordDuration:'',
      tableData:[],
      assetsLists: [],
      assetsFlag:"",
    };
  },
  mounted() {
    let record_id = this.$route.query.record_id;
    let post_data = qs.stringify({ record_id: record_id });
    apiGetRecord(post_data)
      .then((res) => {
        let apiData = {"recordTitle":res.data.title,"recordDuration":res.data.audio_duration};
        this.tableData.push(apiData);
        //  this.recordTitle = res.data.title;
        //this.recordDuration=res.data.audio_duration;
        //获取record信息；
        console.log(res);
      })
      .catch((res) => {
        alert("网络开小差了，请稍后再试试！");
        console.log(res);
      });
    
    apiGetAssets()
      .then((assetsRes) => {
        if(assetsRes.code==200){
          if(assetsRes.data == undefined || assetsRes.data.length <= 0 ){
            this.assetsFlag="empty";
          }else{
            this.assetsLists = assetsRes.data;
          }
        }
      })
      .catch((assetsRes) => {
        alert("网络开小差了，请稍后再试试！");
        console.log(assetsRes);

      });
  
  },
  methods: {
    apiCreateTransferOrderClick(){
      let record_id = this.$route.query.record_id;
      let post_data = qs.stringify({ record_id: record_id });

      apiCreateTransferOrder(post_data)
      .then((res) => {
        if(res.code!=200){
          alert("网络异常");
          return false;
        }else{
           this.$router.push({ 
            path: "/confirm",
            query:{record_id:record_id}
           }); //成功后跳转
        }
      })
      .catch((res) => {
        alert("网络开小差了，请稍后再试试！");
        console.log(res);
      });
    }
  },
};
</script>
<template >
  <HeaderHome />
 
  <CommBanner2/>

  <div style="margin:30px auto;width:1000px;">
    <el-steps :active="2" align-center>
      <el-step title="步骤1" description="上传音频"></el-step>
      <el-step title="步骤2" description="生成订单"></el-step>
      <el-step title="步骤3" description="支付时长卡"></el-step>
      <el-step title="步骤4" description="等待转写完成"></el-step>
      <el-step title="步骤5" description="查看/导出"></el-step>
    </el-steps>
  </div>

  <div style="width: 1000px; background: #fff; padding: 20px; margin: 0 auto;">
    <p class="subtitle">确认订单</p>
    <div class="my-div">
      <el-table
      :data="tableData"
      height="100"
      :header-cell-style="{background:'#eef1f6',color:'#606266'}"
      style="width: 1000px;">
      <el-table-column type="index" width="200" label="序号" align="center"></el-table-column>
      <el-table-column
        prop="recordTitle"
        label="名称"
        width="300">
      </el-table-column>
      <el-table-column
        prop="recordDuration"
        label="音频时长"
        width="200">
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        width="298">
      </el-table-column>
      </el-table>   
    </div>

    <div style="width: 1000px;display: block;margin:30px;text-align: center;">
        <el-button type="primary" @click="apiCreateTransferOrderClick">提交转写</el-button>
    </div>

  </div>

  <!--底部关于我们begin-->
  <FooterHome />
  <!--底部关于我们end-->

  <HelpRight />
</template>
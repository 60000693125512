<template>
  <div
    class="protocol"
    style="width: 80%; margin: 0 auto; word-wrap: break-word"
  >
    <center><h1>服务协议</h1></center>
    <p>
      欢迎使用极速转写提供的产品与/或服务。请您（以下可称“用户”或“您”）仔细阅读以下条款。
    </p>
    <p>
      本协议是您与极速转写开发团队之间就您对极速转写服务的使用及相关服务所订立的协议，
      您应当在仔细阅读、充分理解本协议后使用极速转写的产品与/或服务，您使用极速转写提供的产品与/或服务的行为（下载、安装、启动、注册、登录以及使用），
      都表示您同意并接受本协议的全部条款并愿受其拘束；如果您不愿意接受本协议的任一条款，您应不使用或停止使用极速转写提供的任何产品与/服务。
      您应承诺享有履行本协议的资格和能力，如您是未成年人，您还应要求您的监护人仔细阅读本协议，并取得他/他们的同意。
    </p>
    <p>
      本协议内容包含本协议正文以及您在使用极速转写某一特定服务时，该服务可能会另有单独的协议、相关业务规则等（以下统称为“规则”）。
      所有极速转写开发团队发布的或将来可能发布的规则为本协议不可分割的组成部分，与本协议具有同等法律效力。
      您承诺接受并遵守本协议的约定。如果您不同意本协议的约定，您应立即停止注册程序或停止使用极速转写的产品与/或服务；
      如您继续访问和使用极速转写的产品与/或服务，即视为您已确知并完全同意本协议各项内容。
    </p>
    <p>
      极速转写开发团队有权在不另行通知的情况下，随时对本协议的内容进行修改，修改的内容即时生效并适用。您可随时登录极速转写网站查阅最新版服务协议，
      如果您不同意修改后的任一条款，您应不使用或停止使用极速转写提供的任何产品与/或服务；
      如果您继续选择使用则视为您已经完全接受修改后的协议。
    </p>
    <h2>一. 服务说明</h2>
    <p>
      1.极速转写是由极速转写开发团队研发制作、控制和运营的，向广大用户提供语音转文字技术的在线服务产品，
      可为用户提供多场景的语音转文字以及会议同传的服务（以下简称“本服务”）。
    </p>
    <p>2. 极速转写开发团队向用户提供极速转写的产品与/或服务，包括但不限于：</p>
    <p>
      • 2.1
      极速转写网站xxx.com以及由极速转写直接拥有或运营的任何网站（下称“极速转写网站”）；
    </p>
    <p>
      • 2.2
      极速转写开发团队直接拥有或者运营的客户端包括但不限于PC、手机、智能设备等全部终端客户端产品（下称“极速转写客户端”）；
    </p>
    <p>• 2.3 极速转写的虚拟平台币服务，包括时长卡等（下称“虚拟币服务”）；</p>
    <p>• 2.4 极速转写的用户积分服务（下称“积分服务”）；</p>
    <p>
      3.
      本协议适用于极速转写的所有产品与/或服务，包括但不限于软件、网站、服务以及包含的相关产品或服务功能，
      用户终端包括但不限于PC端、移动终端等其他智能终端。
    </p>
    <p>
      4.
      极速转写开发团队官方公布的方式为下载、安装、启动、浏览、注册、登录、使用极速转写服务的唯一合法方式，用户通过其他任何未经过极速转写开发团队合法授权的渠道、任何途径、
      任何方式获取的极速转写的产品与服务（包括且不限于账号、积分、虚拟币、积分商品、客户端下载等服务）均为非法取得，极速转写开发团队概不承认其效力，
      且一经发现极速转写开发团队有权立即做出删除、取消、清零、封号等处理，任何因此导致的一切不利后果均由用户自行承担。
    </p>
    <p>
      5.
      用户理解并认可极速转写开发团队享有如下权利，极速转写开发团队行使如下权利不视为违约，用户不予追究或者豁免极速转写开发团队的相关法律责任：
      用户有权长期使用其合法获得的极速转写开发团队账号及账号下积分、虚拟币、虚拟积分商品等，但是用户确认其仅享有上述服务和产品的使用权，
      上述服务和产品及其衍生物的所有权及知识产权均归极速转写开发团队所有（但是经合法渠道取得的实体产品所有权以及账号下归属于用户的个人财产所有权除外）。
    </p>
    <p>
      6.
      极速转写开发团队有权提前向用户公告（包括但不限于弹出页面公告、极速转写开发团队官方网站公告）以修改、替换、升级与极速转写提供的任何产品与/或服务和/或上述产品与/或服务相关的任何软件。
      如果用户不同意或者不接受极速转写开发团队提供的任何产品与/或服务和/或上述产品与/或服务相关软件的修改、替换、升级，请直接拒绝、停止、取消，
      否则视为用户同意并接受极速转写开发团队提供的任何产品与/或服务和/或上述相关软件的修改、替代、升级，同时该同意并接受的行为仍受本协议约束。
    </p>
    <p>
      7.
      对于极速转写客户端，极速转写开发团队授予您个人可撤销的、有限的、不可转让的及非排他性的许可，您可以为非商业目的在终端设备上下载、安装、运行、使用极速转写客户端。
      本许可不包括：任何转售或商业使用；数据挖掘、机器人或类似数据收集和提取工具的任何使用。本使用条件中未明确授予您的权利均由极速转写开发团队保留。
      您只能在法律允许的情况下使用本极速转写客户端。如果您不遵守这些使用条件，极速转写开发团队将有权立即中止或终止对您授予的许可。
    </p>
    <h2>二. 软件获取、安装、更新</h2>
    <p>
      1.
      您可以通过极速转写网站或者极速转写开发团队授权的第三方网站、平台下载极速转写客户端。如果您从未经极速转写开发团队授权的第三方网站、平台获取本软件或与本软件名称相同的安装程序，
      极速转写开发团队无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。
    </p>
    <p>
      2.
      极速转写开发团队可能为不同的移动终端设备开发了不同的软件版本，您应当根据实际情况选择下载合适的版本进行安装，否则无法享受到相应服务，且您不得将极速转写客户端安装在未经极速转写开发团队明示许可的其他终端设备上。
      由于您未正确安装合适的客户端版本所带来的风险及损失，极速转写开发团队不承担任何责任。
    </p>
    <p>
      3.
      下载安装程序后，您需要按照该程序提示的步骤正确安装。为提供更加优质、安全的服务，在极速转写客户端安装时极速转写开发团队可能推荐您安装其他软件，
      您可以自由选择安装或不安装。
    </p>
    <p>
      4.
      如果您不再需要使用极速转写客户端或者需要安装新版客户端，您可以自行卸载极速转写客户端。如果您愿意帮助极速转写开发团队改进产品服务，请告知卸载的原因。
    </p>
    <p>
      5.
      为了增进用户体验、完善服务内容，极速转写开发团队将不断努力开发新的服务，并为您不时提供极速转写客户端的更新（这些更新可能会采取客户端替换、修改、功能强化、版本升级等形式）。
    </p>
    <p>
      6.
      为了改善用户体验，并保证服务的安全性和功能的一致性，极速转写开发团队有权不经向您特别通知而对极速转写客户端进行更新，或者对极速转写客户端的部分功能效果进行改变或限制。
    </p>
    <p>
      7.
      极速转写客户端新版本发布后，旧版本的客户端可能无法使用。极速转写开发团队不保证旧版本客户端继续可用及相应的客户服务，请您随时核对并下载最新版本。
    </p>
    <h2>三. 账户注册及管理</h2>
    <p>
      1.
      您使用极速转写的产品与/或服务可以自行申请注册一个账户，或者使用您已有且极速转写开发团队认可的第三方账户进行授权登录。根据相关法律法规和政策规定，
      在您申请注册账户时，必须填写真实、有效的手机号码，并通过手机验证短信来完成账户注册。若因您填写的手机号码不完整或不准确，
      则可能无法使用极速转写的产品与/或服务或在使用过程中受到限制。极速转写开发团队有权要求您填写个人真实身份信息，有权随时检查或核实信息，
      有权因疑问而限制、关闭您的账户，您有义务配合工作，并无条件接受极速转写开发团队的决定。
    </p>
    <p>
      2.
      您申请注册账户归极速转写开发团队所有，您拥有使用权。未经极速转写开发团队书面许可，该账户不可转让、不可出借或出租、不可赠与、不可继承。
      您不得违反本协议约定将您的注册账户用于本协议约定以外的其他目的，否则，极速转写开发团队有权随时单方中止或终止向您提供服务。
    </p>
    <p>
      3.
      您须对注册账户进行妥善保管，并应正确和安全地使用账户信息（包括但不限于账号及密码）。您应对您账户下发生的一切活动承担全部法律责任，无论该活动是由您本人进行，还是由您授权的第三方实行的。
      如因您保密措施不当或您的其他行为，致使账户信息等丢失或泄漏所引起的一切损失和后果，均由您自行承担。
    </p>
    <p>
      4.
      如您的账户遭到第三方使用或发生其他任何问题，您有义务及时通知极速转写开发团队（包括但不限于提供您的身份信息和相关身份资料、相关事实情况及您的要求等）。
      极速转写开发团队在收到您的有效请求并核实身份后，会根据不同情况采取相应措施。若您提供的信息不真实或不完整，
      导致极速转写开发团队无法核实您的身份或判断您的需求而未进行及时处理的所造成的一切损失由您自行承担。同时，您理解极速转写开发团队的回复和采取措施需要合理期限。
      极速转写开发团队不对非极速转写开发团队原因造成的任何损失承担责任。
    </p>
    <p>
      5.
      如果您使用极速转写的产品与/或服务，即表示您愿意接受极速转写开发团队发出的有关本服务的相关邮件及或极速转写开发团队系统消息（包括但不限于商品促销等物料信息）。
      对因此有可能给您造成的打扰，极速转写开发团队不承担任何责任。
    </p>
    <p>
      6.
      如果您不登录账户，那么极速转写开发团队仅能将文件存储在您安装极速转写客户端的终端设备中，极速转写开发团队不能保证文件的稳定性，即在您更换客户端、删除软件等情况下会导致文件永久删除；
      您登录账户后方可将您存储在极速转写客户端内的文件主动同步上传到极速转写网站。
    </p>
    <h2>四. 声明与保证</h2>
    <p>
      1.
      您声明并保证具备履行本协议项下义务和享有本协议项下权益的资格和能力、尊重极速转写开发团队和其他用户的合法权益、不贬低极速转写开发团队及其所有产品和服务的声誉。
    </p>
    <p>
      2.
      您声明并保证，必须为自己使用极速转写的产品与/或服务下的一切行为负责，并保证在极速转写的产品与/或服务上发布的任何内容合法、合规、符合社会公序良俗。
    </p>
    <p>
      3.
      极速转写开发团队致力使极速转写的产品与/或服务成为文明、理性、友善、高质量的信息交流分享平台，极速转写开发团队推动交流业务发展的同时，不断加强相应的信息安全管理能力，完善评论自律，
      切实履行社会责任，遵守国家法律法规，尊重公民合法权益，尊重社会公序良俗。您保证评论、发布、传播的内容应自觉遵守法律法规、社会主义制度、国家利益、公民合法权益、社会公共秩序、道德风尚和信息真实性等“七条底线”要求。
      您保证不会利用极速转写的产品与/或服务上传、发布、标记任何含有下列内容之一的信息或内容:
    </p>
    <p>• 3.1 反对宪法所确定的基本原则的；</p>
    <p>• 3.2 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
    <p>• 3.3 损害国家荣誉和利益的；</p>
    <p>• 3.4 煽动民族仇恨、民族歧视、破坏民族团结的；</p>
    <p>• 3.5 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
    <p>• 3.6 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
    <p>• 3.7 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
    <p>• 3.8 侮辱或者诽谤他人，侵犯任何第三方的知识产权、或隐私权的内容的；</p>
    <p>• 3.9 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</p>
    <p>• 3.10 以非法民间组织名义活动的；</p>
    <p>
      • 3.11
      含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；
    </p>
    <p>
      • 3.12
      含有中国法律、法规、规章、条例以及社会良好道德规范所限制或禁止的其它内容的。
    </p>
    <p>
      4.
      您声明并保证，不会利用极速转写的产品与/或服务进行故意制作、传播计算机病毒等破坏性程序，不得对极速转写的产品与/或服务以及与极速转写的产品与/或服务连接的系统服务器或网络制造干扰、混乱，
      或违反连接极速转写的产品与/或服务的网络的任何要求、程序、政策或规则。
    </p>
    <p>
      5.
      您声明并保证，不会利用极速转写的产品与/或服务可能存在的技术缺陷或漏洞而以各种形式为自己或第三方牟利，或使极速转写开发团队及第三方遭受损失。
    </p>
    <p>
      6.
      包含极速转写的产品与/或服务在内的所有极速转写开发团队产品、服务标识均为极速转写开发团队所有，并受著作权、商标权、专利权及其它法律法规的保护。您声明并保证，未经极速转写开发团队书面许可，
      您不会使用上述内容（包括但不限于：极速转写开发团队的商号、商标、标识或其任何变体、缩写、改写及或URL地址、技术接口等）。
    </p>
    <p>
      7.
      您声明并保证，非经极速转写开发团队书面许可，不得对极速转写的产品与/或服务进行反向工程（reverse
      engineer）、反向编译（decompile）或反汇编（disassemble），
      不得破坏其完整性（包括程序代码、数据等）以及进行任何的修改。
    </p>
    <p>
      8.
      您声明并保证，不会删除、隐匿或改变极速转写的产品与/或服务中显示或其中包含的任何知识产权及其他所有权声明，不会以任何方式干扰或企图干扰本软件的正常运行，
      或者制作、发布、传播可能造成前述后果的工具、方法等。
    </p>
    <p>
      9.
      您在使用极速转写的产品与/或服务传输数据过程中，应当遵守所有适用的出口管制法律法规，
      包括中国、美国、欧盟等所有对该数据传输有司法管辖权的国家和地区的出口管制法。
    </p>
    <h2>五. 通知与反通知</h2>
    <p>
      1.
      极速转写的产品与/或服务中的内容是用户自行上传的，若您认为上述内容、信息涉嫌侵犯您的合法权益，您可以向极速转写开发团队提出书面权利通知书，并提供相应身份证明、权属证明及详细的侵权情况证明。极速转写开发团队收到上述文件后会进行初步形式审核，
      如您提交的材料不符合要求，或在极速转写开发团队要求修改或补充后2个工作日内，您未回复或未能补充符合要求的材料，极速转写开发团队将视情况不予进行处理；如您提交的材料符合要求，极速转写开发团队将对所涉侵权的内容进行处理。
      极速转写开发团队提醒您注意：如您主张侵权的陈述失实，您将单独承担因此而产生的全部法律责任，极速转写开发团队对依照通知而采取的处理行为不承担任何法律责任。
    </p>
    <p>
      2.
      极速转写开发团队根据前述原因已处理的内容，该内容的提供者可以依法向极速转写开发团队发出关于被处理内容不侵犯您合法权益的反通知，并提供相应身份证明、权属证明。
      收到反通知后，极速转写开发团队可以恢复被删除、断开链接的内容，且依法对该恢复行为不承担任何法律责任。
    </p>
    <h2>六. 个人信息保护</h2>
    <p>
      1.
      保护用户个人信息是极速转写开发团队的一项基本原则。您在使用极速转写的产品与/或服务的过程中，极速转写开发团队可能收集一些您的个人信息，未经您的同意，极速转写开发团队不会向极速转写开发团队及其关联公司以外的任何公司、组织和个人披露您的个人信息，
      但以下情况除外：（1） 极速转写开发团队已获得您的明确授权；（2）
      极速转写开发团队为提升产品和服务质量；（3）
      根据有关的法律法规要求，极速转写开发团队负有披露义务的； （4）
      司法机关或行政机关基于法定程序要求极速转写开发团队提供的；（5）
      为维护社会公共利益及极速转写开发团队合法权益，在合理范围内进行披露的。（6）
      法律法规另有规定的。
    </p>
    <p>
      2.
      极速转写开发团队将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。
      但是，极速转写开发团队不能保证现有的安全技术措施能确保您的个人信息不受任何形式的侵犯。
    </p>
    <p>
      3.
      极速转写开发团队非常重视对未成年人个人信息的保护。若您是14周岁以下的未成年人，在使用极速转写的产品与/或服务前应事先取得您家长或法定监护人的同意，
      并在您家长或法定监护人的指导下使用本软件。
    </p>
    <p>
      4.
      其他隐私保护相关条款可参见《极速转写开发团队公司隐私政策》，本协议约定与《极速转写开发团队公司隐私政策》条款有不一致之处，适用本协议。《极速转写开发团队公司隐私政策》的相关条款在本协议中未约定的， 依照《极速转写开发团队公司隐私政策》约定执行。
    </p>
    <h2>七. 广告</h2>
    <p>
      1.
      您同意极速转写开发团队可以在提供服务的过程中自行或由第三方广告商向您发送广告、推广或宣传信息（包括商业与非商业信息），其方式和范围可不经向您特别通知而变更。
    </p>
    <p>
      2.
      极速转写开发团队依照法律的规定对广告商履行相关义务，您应当自行判断广告信息的真实性并为自己的判断行为负责，除法律明确规定外，您因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，极速转写开发团队不承担责任。
    </p>
    <p>
      3.
      您同意，对极速转写服务中出现的广告信息，您应审慎判断其真实性和可靠性，除法律明确规定外，您应对依该广告信息进行的交易负责。
    </p>
    <p>
      4.
      您同意，极速转写开发团队在产品中向您提供广告信息。因为这是极速转写开发团队为所有用户提供综合服务的有效对价，您阅读本协议即视为对该规则的理解和接受。
    </p>
    <h2>八. 收费服务</h2>
    <p>
      1.
      极速转写的部分服务是以收费方式提供的，如您使用收费服务，极速转写会在相关页面上做出提示说明，如果您拒绝支付该等费用，则不能使用相关服务。
    </p>
    <p>
      2.
      极速转写开发团队将有权决定极速转写所提供的服务的资费标准和收费方式，极速转写开发团队可能会就不同的服务制定不同的资费标准和收费方式，也可能按照极速转写所提供的服务的不同阶段确定不同的资费标准和收费方式。
    </p>
    <p>
      3.
      极速转写开发团队可能根据实际需要随时对收费服务的收费标准、方式进行修改和变更，极速转写开发团队也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，极速转写开发团队将在相应服务页面进行通知或公告。如果您不同意上述修改、变更或付费内容，则应停止使用该服务。
    </p>
    <p>
      4.
      您充值、开通包月或购买其他服务后，人民币金额立即转换为时长卡、包月特权或其他相关服务权限，任何情况下，不能退还返现、不能转让。请您在充值、付费前仔细阅读相应服务页面的充值付费须知内容与/或协议。
    </p>
    <h2>九. 注意事项</h2>
    <p>
      1.
      您理解并同意：为了向您提供有效的服务，本软件会利用您移动通讯终端的处理器和带宽等资源。本软件使用过程中可能产生数据流量的费用，您需自行向运营商了解相关资费信息，并自行承担相关费用。
    </p>
    <p>
      2.
      您理解并同意极速转写开发团队将会尽其商业上的合理努力保障您在极速转写的产品与/或服务中的数据存储安全，但是，极速转写开发团队并不能就此提供完全保证，包括但不限于以下情形：
    </p>
    <p>
      • 2.1 极速转写开发团队不对您在极速转写的产品与/或服务中相关数据的删除或储存失败负责；
    </p>
    <p>
      • 2.2
      极速转写开发团队有权根据实际情况自行决定单个用户在极速转写的产品与/或服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。您可根据自己的需要自行备份极速转写的产品与/或服务中的相关数据；
    </p>
    <p>
      • 2.3
      如果您停止使用极速转写的产品与/或服务或服务被终止或取消，极速转写开发团队可以从服务器上永久地删除您的数据。服务停止、终止或取消后，极速转写开发团队没有义务向您返还任何数据。
    </p>
    <p>
      3.
      您充分了解并同意，应对本软件服务的内容自行加以判断，并须自行承担如下极速转写开发团队不可掌控的风险，包括但不限于：
    </p>
    <p>
      • 3.1
      由于网络攻击，黑客行为，恶意程序和电脑病毒等不可抗力而导致的用户个人信息丢失、泄漏等风险；
    </p>
    <p>
      • 3.2
      由于用户选择极速转写客户端版本与手机型号不相匹配所导致的任何问题或损害；
    </p>
    <p>
      • 3.3
      您在使用极速转写的产品与/或服务访问第三方产品时，因第三方产品及相关内容所可能导致的风险；
    </p>
    <p>
      • 3.4 用户发布的内容被他人转发、分享，因此等传播可能带来的风险和责任；
    </p>
    <p>
      • 3.5
      由于无线网络信号不稳定、无线网络带宽小等原因，所引起的资料同步不完整、页面打开速度慢等风险。
    </p>
    <h2>十. 风险及免责</h2>
    <p>
      1.
      您完全理解并同意，极速转写的产品与/或服务同大多数互联网在线服务一样，可能会受多种因素影响（包括但不限于用户原因、网络服务质量、社会环境等）；
      也可能会受各种安全问题的侵扰（包括但不限于他人非法利用用户资料，进行现实中的骚扰；用户下载安装的其他客户端或访问的其他网站中可能含有病毒、木马程序或其他恶意程序，威胁您的移动终端设备和数据的安全，继而影响极速转写的产品与/或服务的正常使用等）。
      因此，您应加强信息安全及个人信息的保护意识，注意密码保护，以免遭受损失。
    </p>
    <p>
      2.
      维护极速转写的产品与/或服务安全与正常使用是极速转写开发团队和您的共同责任，极速转写开发团队将按照行业标准合理审慎地采取必要技术措施保护您移动终端设备的信息和数据安全，
      但是您承认和同意极速转写开发团队不能就此提供任何保证。
    </p>
    <p>
      3.
      对于极速转写的产品与/或服务系统故障或升级期间不稳定而影响到的正常运行，极速转写开发团队承诺及时进行处理。但您因此而产生的经济和精神损失，极速转写开发团队不承担任何责任。
      此外，极速转写开发团队保留不经事先通知为维护、升级或其他目的暂停关闭极速转写的产品与/或服务的全部或部分。
    </p>
    <p>
      4.
      因为技术发展水平限制，极速转写开发团队无法保证极速转写的产品与/或服务的功能的准确率。您完全理解并同意，极速转写的产品与/或服务，包括但不限于在听写翻译服务的过程中出现任何错误，
      您都不会追究极速转写开发团队责任，并不因此对极速转写开发团队及极速转写的产品与/或服务进行负面评价。
    </p>
    <p>
      5.
      您完全理解并同意，因业务发展需要，极速转写开发团队保留单方面对极速转写的产品与/或服务的全部或部分在任何时候不经任何通知的情况下变更、限制、中止、终止的权利，
      而无需承担任何责任。
    </p>
    <p>
      6.
      您完全理解并同意，因不可抗力或非极速转写开发团队原因导致的您个人信息被泄漏、窃取所造成损失的，您放弃追究极速转写开发团队的责任。
    </p>
    <p>
      7.
      您完全理解并同意，因极速转写的产品与/或服务使用的第三方软件或技术引发的任何纠纷，应由该第三方负责解决，极速转写开发团队不承担任何责任。
      极速转写开发团队不对第三方软件或技术提供客服支持，若您需要获取支持，请与第三方联系。
    </p>
    <p>
      9.
      您使用极速转写的产品与/或服务引起的各种特殊、偶然、附带、间接损失，或者是任何利润、收入、销售损失、数据丢失或采购替代商品或服务的成本、财产损害、人身伤害、业务中断、商业信息损失，极速转写开发团队与其供应商均不承担责任。
      极速转写开发团队对您的所有损害承担的总责任限额不超过您使用极速转写的产品与/或服务所支付的价款。
    </p>
    <h2>十一. 违约责任</h2>
    <p>
      1.
      您理解并同意，极速转写开发团队有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动（包括但不限于中止、终止提供服务，删除账户等），
      并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。
    </p>
    <p>
      2.
      您必须保证使用极速转写的产品与/或服务过程中严格遵守国家相关法律法规、以及本协议或相关服务条款的规定，并不得侵犯任何第三方的合法权益。如您违反上述约定，而导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；
      若极速转写开发团队受到第三方投诉（包括但不限于第三方以发函、媒体报道等形式指控极速转写开发团队侵权，对极速转写开发团队提起诉讼，向行政机关、司法执法机关等的举报使极速转写开发团队遭受审查或质询，下同），
      或使极速转写开发团队面临行政机关、司法执法机关等的审查或质询，极速转写开发团队有权先中止向您提供服务。您在收到通知后，应以自己名义出面与第三方协商、应诉或接受相关机关审查或质询，
      并承担所有法律责任（包括但不限于诉讼费、律师费、赔偿款）及赔偿因此给极速转写开发团队造成的全部损失。
    </p>
    <h2>十二. 通知和送达</h2>
    <p>
      1.
      您在使用极速转写的产品与/或服务过程中有任何意见或建议，可通过意见反馈渠道提出，极速转写开发团队将向您及时做出反馈。您应保证您的联系方式畅通，
      以接收来自极速转写开发团队发出的邮件、电话或信息，极速转写开发团队不对您未收到通知而引起的任何损失承担责任。
    </p>
    <p>
      2.
      极速转写开发团队向您发出的通知，将采用电子邮件（注册极速转写开发团队账户时预留）或极速转写网站页面公告的方式，该等通知于发送/公告之日即视为送达。
    </p>
    <p>
      3.
      您可以按照极速转写开发团队所公布的如下联系方式向极速转写开发团队发出通知，极速转写开发团队在收到通知之日起视为送达。
    </p>
    <p>电子邮件地址：xxxxx@xxx.com</p>
    <h2>十三. 其它条款</h2>
    <p>
      1.
      如本协议条款之任何一部分与中华人民共和国法律相抵触，则该部分条款应按法律规定重新解释，部分条款之无效或重新解释不影响其它条款之法律效力。
    </p>
    <p>
      2.
      您和极速转写开发团队均为独立的主体，在任何情况下本协议不构成双方之间的代理、合伙、合营或雇佣关系。
    </p>
    <p>
      3.
      您声明并同意极速转写开发团队可指定第三方成为本协议的受益人，并有权直接履行和享受本协议项下权利。
    </p>
    <p>
      4.
      本协议条款之效力和解释均适用中华人民共和国之法律。您与极速转写开发团队一致同意凡因本协议所产生的纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交北京市海淀区人民法院裁决。
    </p>
  </div>
</template>
<template>
  <HeaderHome />
  <CommBanner2 />
  <div class="rg_layout">
        <div class="rg_left">
            <p>用户登陆</p>
            <p>USER LOGIN</p>
        </div>
        <div class="rg_center">
            <div class="login_form">
                <!--定义表单 form-->
                    <table>
                        <tr>
                            <td class="td_left"><label for="username">用户名</label></td>
                            <td class="td_right">
                                <input type="text" id="username" placeholder="请输入用户名" autocomplete="off" v-model.trim="loginForm.loginName">
                            </td>
                        </tr>
                        <tr>
                            <td class="td_left"><label for="password">密码</label></td>
                            <td class="td_right">
                               <input type="password" id="password" name="password" placeholder="请输入密码" v-model.trim="loginForm.password">
                            </td>
                        </tr>
                    </table>
            </div>
            <div class="btn_center"><button type="button" id="btn_sub" @click="login">登 录</button></div>

        </div>
        <div class="rg_right">
            <p>还没有账号?<a href="/reg">立即注册</a></p>
        </div>
    </div>
 
   <FooterHome />
</template>

      <script>
import qs from "qs";
import { apiUserLogin } from "../utils/api.js";
import HeaderHome from './HeaderHome.vue';
import FooterHome from './FooterHome.vue';
import CommBanner2 from './CommBanner2.vue';


export default {
  name: "UserLogin",
  components:{
     HeaderHome,FooterHome,CommBanner2
  },
  data() {
    return {
      loginForm: {
        loginName: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      // console.log('submit!',this.loginForm);
      //表单验证
      if (this.loginForm.loginName == "") {
        this.$message({
          message: "请输入用户名",
          type: "error",
        });
        return;
      }
      if (this.loginForm.password == "") {
        this.$message({
          message: "请输入密码",
          type: "error",
        });
        return;
      }
      //发送登陆请求
      let post_data = qs.stringify({
        name: this.loginForm.loginName,
        password: this.loginForm.password,
      });
      if (this.loginForm.loginName != "" && this.loginForm.password != "") {
        let that = this;
        apiUserLogin(post_data)
          .then((res) => {
            if (res.code == 200) {
              that.$store.commit("setToken", res.data.token);
              that.$store.commit("setUserInfo", res.data.user);
              console.log("登陆成功");
              setTimeout(()=>{this.$router.push("/audio");},1000);
             // this.$router.push("/audio");
            } else {
              console.log("登陆失败");
            }
          })
          .catch((res) => {
            alert("网络开小差了，请稍后再试试！");
            console.log(res);
          });
      }
    },

    toregister() {
      this.$router.push("/register");
    },

    logout() {
      // 第一个参数为key的名，第二个参数为空
      this.$store.commit("delToken");
      console.log("注销token");
      //localStorage.removeItem("token")
      ///localStorage.setItem("token", "")
    },
  },
};
</script>
<style  scoped>
       .rg_layout{
            width: 1000px;
            height: 500px;
            border: 8px solid #EEEEEE;
            background-color: white;
            /*让div水平居中*/
            margin: auto;
            margin-top: 15px;
            margin-bottom: 15px;
        }
        .rg_left{
            /*border: 1px solid red;*/
            float: left;
            margin: 15px;
        }
        .rg_left > p:first-child{
            color: #FFD026;
            font-size: 20px;
        }
        .rg_left > p:last-child{
            color: #A6A6A6;
            font-size: 20px;
        }
        .rg_center{
            /*border: 1px solid red;*/
            float: left;
            width: 600px;
        }
        .login_form{
          margin: auto;
          margin-top: 150px;
          margin-left: 100px;
        }
        .rg_right{
            /*border: 1px solid red;*/
            float: right;
            margin: 15px;
        }
        .rg_right > p:first-child{
            font-size: 15px;
        }
        .rg_right p a{
            color: pink;
        }
        .td_left{
            width: 100px;
            text-align: right;
            height: 45px;
        }
        .td_right{
            padding-left: 50px;
        }
        #username,#password,#email,#name,#tel,#birthday,#checkcode{
            width: 251px;
            height: 32px;
            border: #A6A6A6 1px solid;
            /*设置圆角边框*/
            border-radius: 5px;
            padding-left: 10px;
        }
        #checkcode{
            width: 110px;
        }
        #img_check{
            height: 32px;
            vertical-align: middle;
        }
        .btn_center{
          margin: auto;
          margin-top: 20px;
          margin-left: 160px;
        }
        #btn_sub{
            width: 150px;
            height: 40px;
            background-color: dodgerblue;
            border: #cccccc 1px solid;
            cursor: pointer;
            color: #ffffff;
            left: 50%;  
            margin-left: 100px;
        }
</style>
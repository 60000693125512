import { createApp } from 'vue'
import App from './App.vue'
import store from './store/store.js'

import '@/assets/css/style.css'

//引入
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// 导入路由模块
import router from './router'
const app = createApp(App)
// 挂载路由模块
app.use(router)
app.use(store)


//注册element
app.use(ElementPlus)
app.mount('#app')


<template>
  <div class="top-bg">
    <div class="w1260 top">
      <a href="/index" class="l">
        <img :src="require('@/assets/images/logo.png')" />
      </a>
      <div class="nav l">
        <a href="/index">首页</a>
        <a href="/audio">上传音频</a>
        <a href="/record_list">我的转写</a>
        <a href="/recharge" rel="nofollow">购买时长卡</a>
        <a href="/member">会员中心</a>
        <a href="/introduce">常见问题</a>
        <a href="/feedback">在线反馈</a>
      </div>
      <div class="r">
        <UserInfo />
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>
<style scoped>
.bai {
  color: #666;
  padding-top: 8px;
  text-align: right;
  padding-top: 25px;
}
.bai a {
  color: #666;
}
</style>
<script>
import store from "../store/store";
import UserInfo from "./UserInfo.vue";

export default {
  name: "HeaderHome",
  components: {
    UserInfo,
  },
  data() {
    return {
      userName: "",
    };
  },
  mounted() {
    this.userName = store.getters.getUser;
    //alert(store.getters.getUser);
  },
  methods: {
    /*
    logout() {
      // 第一个参数为key的名，第二个参数为空
      store.commit("delToken");
      setTimeout(() => {
        this.$router.push("/login");
      }, 1000);
      alert("退出登陆");
      console.log("注销token");
      //localStorage.removeItem("token")
      ///localStorage.setItem("token", "")
    },  */
  },
};
</script>

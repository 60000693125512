<script>
import { apiRecordContent } from "../utils/api.js";
import qs from "qs";
import HeaderHome from "./HeaderHome.vue";
import FooterHome from "./FooterHome.vue";
import CommBanner2 from './CommBanner2.vue';


export default {
  components: {
    HeaderHome,
    FooterHome,
    CommBanner2,
  },
  data() {
    return {
      title: "",
      audioURL: "",
      content:"",
      createTime:"",
      recDuration:"",
      tranStatus:"",
      textToCopy: '这里是要复制的文本' // 设置要复制的文本内容
    };
  },
  mounted() {
    let record_id = this.$route.query.record_id;
    let post_data = qs.stringify({ record_id: record_id });
    apiRecordContent(post_data)
      .then((res) => {
        if (res.code == 200) {
          console.log(res.data);
          this.title = res.data.title;
          this.audioURL = res.data.audio_url;
          this.content = res.data.content;
          this.createTime = res.data.create_time;
          this.recDuration = res.data.audio_duration;

          if (res.data.transfer_status==1){
            this.tranStatus="未开始"
          }else if (res.data.transfer_status==2){
            this.tranStatus="转写中"
          }else if (res.data.transfer_status==3){
            this.tranStatus="已完成"
          }else if (res.data.transfer_status==4){
            this.tranStatus="转写错误"
          }else{
            this.tranStatus="状态不存在"
          }
        } else {
          console.log("登陆失败");
        }

        this.$refs.audioPlayer.src = this.audioURL;
        this.$refs.audioPlayer.pause();
        // 直接播放声音
        //this.$refs.audioPlayer.play();
      })
      .catch((res) => {
        alert("网络开小差了，请稍后再试试！");
        console.log(res);
      });
  },
  methods: {
    copyText() {
      const el = document.createElement('textarea');
      // 将要复制的文本内容放入新创建的 textarea 中
      this.content = this.content.replace(/<p>/g, "");
      el.value = this.content.replace(/<\/p>/g, "\n\n");
      
      // 将 textarea 添加到页面中并选中其中的文本
      document.body.appendChild(el);
      el.select();
      
      try {
        // 进行复制操作
        document.execCommand('copy');
        alert('已成功复制！');
      } catch (err) {
        console.error('无法复制文本', err);
      } finally {
        // 移除 textarea 元素
        document.body.removeChild(el);
      }
    }
  }
  
};
</script>
<style scoped>
</style>
<template >
  <HeaderHome />
  <!--main-->

  <CommBanner2/>

  <div class="w1260" style="margin-bottom: 40px">
    <div style="width: 1240px; background: #fff; padding: 20px">
      <p class="subtitle2">转写文档：{{ title }}</p>
      <p class="subtitle2">转写状态：{{ tranStatus }}</p>
      <p class="subtitle2">创建时间：{{ createTime }}</p>
      <p class="subtitle2">音频时长：{{ recDuration }}</p>
      <p class="subtitle2">原音播放：<br>
        <audio class="audio" ref="audioPlayer" controls autoplay="false"><source :src="audioURL" type="audio/mpeg">Your browser does not support the audio element.</audio>
      </p>
      <p class="subtitle2">正文：  <el-button type="primary" round plain @click="copyText" size="small">一键复制文本</el-button>
</p>
      <div class="rec-content" v-html="content" v-copy="textToCopy"></div>
    </div>
    <div class="clear"></div>
  </div>

  <!--底部关于我们begin-->
  <FooterHome />
  <!--底部关于我们end-->
</template>
<style scoped>
.subtitle2 audio{
  margin-top: 10px;
}
</style>